import { RxCross2 } from "react-icons/rx";
import "./PreviewPage.scss";
import { VscSmiley } from "react-icons/vsc";
import Picker from "emoji-picker-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useChatContext } from "../../../context/ChatProvider";
import { isMobile } from "react-device-detect";
import { IoMdDocument } from "react-icons/io";
import { MdAdd } from "react-icons/md";
import ChatSendBtn from "../ChatPage/ChatSendBtn";
import { FaCirclePlay } from "react-icons/fa6";
import { convertBytes } from "../../../../../utils/axiosInstance";
import { AiFillAudio } from "react-icons/ai";

type PreviewPageProps = {
  attachments?: any;
  onSend: Function;
  type?: string;
  setMessage: Function;
  message: any;
};

export default function PreviewPage(props: PreviewPageProps) {
  const { attachments, onSend, setMessage, message } = props;
  const [currentAttachment, setCurrentAttachment] = useState<any>(
    attachments[0] || null
  );
  const [previewEmoji, setPreviewEmoji] = useState(false);
  const [isCutPreview, setIsCutPreview] = useState(false);
  const chatContext = useChatContext();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [fileInputKey, setFileInputKey] = useState(0);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const emojiRef = useRef<HTMLDivElement>(null);

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      if (!chatContext.isLoading) {
        onSend();
        event.target.value = "";
      }
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleRemoveAttachment = useCallback(
    (attachment: any) => {
      const updatedAttachments = attachments.filter(
        (item: any) => item.url !== attachment.url
      );
      chatContext?.setAttachment(updatedAttachments);
      if (currentAttachment?.url === attachment.url) {
        if (updatedAttachments.length > 0) {
          setCurrentAttachment(updatedAttachments[0]);
        } else {
          setCurrentAttachment(null);
        }
      }
    },
    [currentAttachment]
  );

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      const urls = [];
      const attachments = Array.from(selectedFiles).map((file: any) => {
        const fileUrl = URL.createObjectURL(file);
        return {
          file,
          url: fileUrl,
          name: file.name,
          type: file.type.includes("image")
            ? "image"
            : file.type.includes("video")
            ? "video"
            : file.type.includes("audio")
            ? "audio"
            : "file",
        };
      });
      attachments.forEach((file) => fileUpload(file));
    }
  };
  const handleEmojiClick = useCallback((emojiData: { emoji: string }) => {
    const input = inputRef.current;
    if (input) {
      const { selectionStart, selectionEnd } = input;
      const start = selectionStart ?? 0;
      const end = selectionEnd ?? start;
      const currentValue = input.value;
      const newValue =
        currentValue.slice(0, start) +
        emojiData.emoji +
        currentValue.slice(end);
      setMessage(newValue);
      input.value = newValue;
      input.setSelectionRange(
        start + emojiData.emoji.length,
        start + emojiData.emoji.length
      );
      input.focus();
    }
  }, []);
  const fileUpload = async (file: any) => {
    chatContext?.setAttachment((prev: any) => [...prev, file]);
    chatContext?.setIsPreview(true);
  };
  return (
    <div className={`preview-container`}>
      <div className="position-relative p-3 preview-content d-flex flex-column">
        <div className="d-flex">
          <div className="cursor-pointer">
            <RxCross2
              fontSize={24}
              color="var(--secondary)"
              title="Close Preview"
              onClick={() => {
                chatContext?.setIsPreview(false);
                chatContext?.setAttachment([]);
              }}
            />
          </div>
          <div className="flex-grow-1 text-center">
            <div className="fw-semibold fs-14">{currentAttachment?.name}</div>
          </div>
        </div>

        <div className={`h-100 ${isMobile ? "container-fulid" : "container"}`}>
          <div className="d-flex flex-column justify-content-between h-100">
            <div className="flex-grow-1 d-flex justify-content-center align-items-center h-100">
              {currentAttachment?.type.includes("image") && (
                <div className="preview-image">
                  <img src={currentAttachment?.url} alt="" />
                </div>
              )}
              {currentAttachment?.type.includes("video") && (
                <video controls className="preview-video mt-3">
                  <source src={currentAttachment?.url} type="video/mp4" />
                </video>
              )}
              {currentAttachment?.type.includes("audio") && (
                <span className="d-flex flex-column align-items-center">
                  <AiFillAudio
                    size={150}
                    color="var(--secondary)"
                    title="audio"
                  />
                </span>
              )}
              {currentAttachment?.type.includes("file") && (
                <div className="d-flex flex-column align-items-center">
                  <IoMdDocument
                    fontSize={150}
                    color="var(--secondary)"
                    title="Document"
                  />
                  <span> {convertBytes(currentAttachment?.file?.size)}</span>
                </div>
              )}
            </div>
            <div className="d-flex position-relative align-items-center gap-4">
              <div className="emoji-wrapper-footer" ref={emojiRef}>
                {previewEmoji && (
                  <Picker
                    autoFocusSearch={false}
                    searchDisabled
                    lazyLoadEmojis={true}
                    onEmojiClick={handleEmojiClick}
                  />
                )}
              </div>
              <div className="flex-grow-1">
                <textarea
                  ref={inputRef}
                  className="footer-input w-100"
                  placeholder="Add a caption"
                  onChange={(e) => setMessage(e?.target?.value)}
                  onKeyDown={handleKeyDown}
                  autoFocus
                  id="inputID"
                  rows={message.length > 246 ? 3 : message.length > 123 ? 2 : 1}
                />
              </div>
              <span
                onClick={() => setPreviewEmoji(!previewEmoji)}
                className="cursor-pointer"
              >
                <VscSmiley size={24} color="var(--secondary)" title="Emoji" />
              </span>
            </div>
          </div>
        </div>

        <div className="preview-footer border-top w-100">
          <div className="d-flex align-items-center p-2">
            <div className="flex-grow-1 d-flex justify-content-center gap-3">
              {attachments?.map((item: any) => (
                <div
                  key={item.name}
                  onClick={() => setCurrentAttachment(item)}
                  className="preview-img-cut d-flex justify-content-center align-items-center"
                  onMouseEnter={() => setIsCutPreview(true)}
                  onMouseLeave={() => setIsCutPreview(false)}
                >
                  {item.type.includes("image") && (
                    <>
                      <img src={item.url} alt="preview" />
                    </>
                  )}
                  {item.type.includes("video") && (
                    <FaCirclePlay
                      fontSize={30}
                      color="var(--secondary)"
                      title="Video"
                    />
                  )}
                  {item.type.includes("file") && (
                    <div>
                      <IoMdDocument
                        fontSize={30}
                        color="var(--secondary)"
                        title="Document"
                      />
                    </div>
                  )}
                  {item.type.includes("audio") && (
                    <span>
                      <AiFillAudio
                        fontSize={30}
                        color="var(--secondary)"
                        title="audio"
                      />
                    </span>
                  )}
                  {isCutPreview && (
                    <span className="preview-img-close p-1">
                      <RxCross2
                        fontSize={20}
                        color="var(--color-white)"
                        title="Remove"
                        onClick={() => handleRemoveAttachment(item)}
                      />
                    </span>
                  )}
                </div>
              ))}
              <div
                className="add-file"
                title="Add file"
                onClick={() => fileInputRef.current?.click()}
              >
                <MdAdd fontSize={24} color="var(--border)" />
              </div>
              <input
                key={fileInputKey} // Change key to force re-render
                type="file"
                multiple
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </div>
            <span
              className="ms-4 cursor-pointer send-btn"
              onClick={() => onSend()}
            >
              <ChatSendBtn size="40px" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
