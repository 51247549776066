import { LiveKitRoom } from "@livekit/components-react";
import { useEffect, useState } from "react";
import { useAuthentication } from "../../../../../context/AuthenticationProvider";
import { LIVEKIT_WEBSOCKET } from "../../../../../utils/axiosInstance";
import LiveKitService from "../../../../../utils/services/livekit.service";
import CallInterface from "./callInterface";

interface LiveKitRoomWrapperProps {
  video: boolean;
  audio: boolean;
  token: string;
  connect: boolean;
  serverUrl: string;
  children?: React.ReactNode;
  "data-lk-theme"?: string;
  style?: React.CSSProperties;
}

function LiveKitRoomWrapper(props: LiveKitRoomWrapperProps) {
  return <LiveKitRoom {...props} />;
}
const PrivateRoom = ({ conversationId, audioCallshow, videoCallshow }: any) => {
  const [token, setToken] = useState<string | null>(null);
  const userContext = useAuthentication();
  useEffect(() => {
    const fetchToken = async () => {
      try {
        if (userContext?.user?.id) {
          const res = await LiveKitService.connectRoom({
            roomName: conversationId,
            participantName: userContext?.user?.id,
          });
          if (res?.token) {
            setToken(res?.token);

            await LiveKitService.updateMeta(
              conversationId,
              userContext?.user?.id,
              userContext?.user
            );
          } else {
            console.error("Token response did not contain a token:", res);
          }
        }
      } catch (error) {
        console.error("Error fetching token:", error);
      }
    };

    fetchToken();
  }, [conversationId, userContext?.user]);

  return token ? (
    <div>
      <LiveKitRoomWrapper
        video={videoCallshow}
        audio={audioCallshow}
        token={token}
        connect={true}
        serverUrl={LIVEKIT_WEBSOCKET}
        data-lk-theme={"default"}
      >
        <CallInterface />
      </LiveKitRoomWrapper>
    </div>
  ) : null;
};

export default PrivateRoom;
