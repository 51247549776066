import Picker from "emoji-picker-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { AiFillAudio } from "react-icons/ai";
import { FaImages, FaRegFileVideo, FaVideo } from "react-icons/fa";
import { IoIosAttach, IoMdDocument, IoMdMic } from "react-icons/io";
import { IoDocumentText } from "react-icons/io5";
import { RiShareForwardFill } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import { VscSmiley } from "react-icons/vsc";
import { useParams } from "react-router-dom";
import { ChatContextProp, useChatContext } from "../../../context/ChatProvider";
import { useUserContext } from "../../../context/UserProvider";
import ChatSendBtn from "../ChatPage/ChatSendBtn";
import GoogleMaps from "../components/Maps/GoogleMaps";
import PreviewPage from "../PreviewPage/PreviewPage";
import "./Footer.scss";

type FooterProps = {
  replyshow: boolean;
  setReplyshow: Function;
  forwardshow: boolean;
  setForwardshow: Function;
  setModalShow: Function;
  setShow: Function;
  setAudioRecordshow: Function;
  audioRecordshow: boolean;
  setShouldScrollToBottom: Function;
};

export default function Footer(props: FooterProps) {
  const {
    replyshow,
    setReplyshow,
    forwardshow,
    setForwardshow,
    setModalShow,
    setShow,
    setAudioRecordshow,
    setShouldScrollToBottom,
  } = props;

  const [showIcons, setShowIcons] = useState(false);
  const [emojishow, setEmojishow] = useState(false);
  const [message, setMessage] = useState<any>("");
  const chatContext = useChatContext();
  const params = useParams();
  const userContext = useUserContext();
  // const inputRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const emojiRef = useRef<HTMLDivElement>(null);
  const [showMaps, setShowMaps] = useState<boolean>(false);

  const sendMessageApi = () => {
    chatContext.sendMessage(
      params.id,
      {
        message: message,
        is_reply: replyshow,
        reply_message_id: chatContext?.repliedMessage?.id,
      },
      userContext?.user.id,
      userContext?.user.name
    );
    setMessage("");
    setReplyshow(false);
    setForwardshow(false);
    setEmojishow(false);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    const BYTES_IN_MB = 1048576;
    const MAX_SIZE_MB = 20;

    for (let key in event.target.files) {
      const sizeInBytes = parseInt(event.target.files[key].size);
      const sizeInMB = sizeInBytes / BYTES_IN_MB;

      if (sizeInMB > MAX_SIZE_MB) {
        alert(
          `Size of entry ${key} is greater than ${MAX_SIZE_MB} MB: ${sizeInMB.toFixed(
            2
          )} MB`
        );
        return;
      }
    }
    if (Array.from(event.target.files).length > 5) {
      event.preventDefault();
      alert(`Cannot upload files more than 5`);
      return;
    }
    setShowIcons(false);
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      const urls = [];
      const attachments = Array.from(selectedFiles).map((file: any) => {
        const fileUrl = URL.createObjectURL(file);
        return {
          file,
          url: fileUrl,
          name: file.name,
          type: file.type.includes("image")
            ? "image"
            : file.type.includes("video")
            ? "video"
            : file.type.includes("audio")
            ? "audio"
            : "file",
        };
      });
      // setFileUrls(attachments.map((att) => att.url));
      attachments.forEach((file) => fileUpload(file));
    }
  };
  const fileUpload = async (file: any) => {
    chatContext?.setAttachment((prev: any) => [...prev, file]);
    chatContext?.setIsPreview(true);
  };
  const id = useParams();
  const sendMessage = () => {
    setMessage("");
    sendMessageApi();
    setReplyshow(false);
    setShouldScrollToBottom(true);
  };

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.value = "";
      chatContext?.setIsPreview(false);
    }
  }, [id]);
  const onSendAttachment = async () => {
    const messageBody: any = {};
    if (chatContext?.attachment && chatContext?.attachment.length > 0) {
      for (const item of chatContext.attachment) {
        try {
          const { file, url, name, type } = item;
          // const blob = await fetchBlob(url);
          const formData = new FormData();
          formData.append("file", file);
          formData.append("filename", name);
          const data = await chatContext.fileUpload(formData, type);
          if (data?.url) {
            if (chatContext?.repliedMessage?.id) {
              messageBody.is_reply = true;
              messageBody.reply_message_id = chatContext?.repliedMessage?.id;
            }
            messageBody.attachment_type = type.toUpperCase();
            messageBody.attachment = data?.url ?? "";
            if (message) {
              messageBody.message = message;
            }

            if (params?.id) {
              chatContext?.sendMessage(
                params?.id,
                messageBody,
                userContext?.user.id,
                userContext?.user.name
              );
              chatContext?.setRepliedMessage({});
              setReplyshow(false);
              setMessage("");
              chatContext?.setAttachment([]);
              chatContext?.setIsPreview(false);
            }
          }
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowIcons(false);
      }
      if (
        emojiRef.current &&
        !emojiRef.current.contains(event.target as Node)
      ) {
        setEmojishow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [params.id, chatContext?.attachment, replyshow]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (
      event.key === "Enter" &&
      inputRef?.current &&
      inputRef?.current.value.trim()
    ) {
      event.preventDefault();
      sendMessageApi();
      setReplyshow(false);
    }
  };

  const handleEmojiClick = useCallback((emojiData: { emoji: string }) => {
    const input = inputRef.current;
    if (input) {
      const { selectionStart, selectionEnd } = input;
      const start = selectionStart ?? 0;
      const end = selectionEnd ?? start;
      const currentValue = input.value;
      const newValue =
        currentValue.slice(0, start) +
        emojiData.emoji +
        currentValue.slice(end);
      setMessage(newValue);
      input.value = newValue;
      input.setSelectionRange(
        start + emojiData.emoji.length,
        start + emojiData.emoji.length
      );
      input.focus();
    }
  }, []);

  return (
    <div className="footer-wrapper flex-grow-1 position-relative">
      <div className=" emoji-wrapper-footer" ref={emojiRef}>
        {emojishow && (
          <Picker
            autoFocusSearch={false}
            searchDisabled
            lazyLoadEmojis={true}
            onEmojiClick={handleEmojiClick}
          />
        )}
      </div>
      <div className=" position-relative d-flex align-items-center">
        <div
          onClick={() => setShowIcons(!showIcons)}
          className="cursor-pointer"
        >
          <IoIosAttach size={30} color="var(--secondary)" title="Attachments" />
        </div>

        {showIcons && (
          <div
            ref={dropdownRef}
            className="attach-container p-1 d-flex flex-column"
          >
            <div className={`button-attach ${showIcons ? "show-icon" : ""}`}>
              <div className="   d-flex align-items-center cursor-pointer position-relative overflow-hidden file-item">
                <input
                  type="file"
                  name="myfile"
                  className=" file-input"
                  multiple
                  accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={handleFileChange}
                />
                <div>
                  <IoDocumentText
                    size={20}
                    color="var(--secondary)"
                    title="Documents"
                  />
                  <span className="px-2 attach-title">Documents</span>
                </div>
              </div>

              <div className="   d-flex align-items-center cursor-pointer position-relative overflow-hidden file-item">
                <input
                  type="file"
                  multiple
                  className=" file-input"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={handleFileChange}
                />
                <div>
                  <FaImages size={20} color="var(--secondary)" title="Photos" />
                  <span className="px-2 attach-title">Photos</span>
                </div>
              </div>
              <div className="   d-flex align-items-center cursor-pointer position-relative overflow-hidden file-item">
                <input
                  type={"file"}
                  name="myfile"
                  multiple
                  className=" file-input"
                  accept="video/mp4, video/mkv, video/webm"
                  onChange={handleFileChange}
                />
                <div>
                  <FaRegFileVideo
                    size={20}
                    color="var(--secondary)"
                    title="Videos"
                  />
                  <span className="px-2 attach-title">Videos</span>
                </div>
              </div>
              <div className="  d-flex align-items-center cursor-pointer position-relative overflow-hidden file-item">
                <input
                  type="file"
                  name="myfile"
                  className=" file-input"
                  multiple
                  accept="audio/mp3, audio/wav, audio/mpeg"
                  onChange={handleFileChange}
                />
                <div>
                  <IoMdMic size={20} color="var(--secondary)" title="Audio" />
                  <span className="px-2 attach-title">Audio</span>
                </div>
              </div>
              {/* <div
                className="  d-flex align-items-center cursor-pointer position-relative overflow-hidden"
                onClick={() => {
                  setShowMaps(true);
                  setShowIcons(false);
                }}
              >
                <div>
                  <IoMdLocate
                    size={20}
                    color="var(--secondary)"
                    title="Audio"
                  />
                  <span className="px-2 attach-title">Location</span>
                </div>
              </div> */}
            </div>
          </div>
        )}

        <span
          onClick={() => setEmojishow(!emojishow)}
          className=" cursor-pointer mx-2"
        >
          <VscSmiley size={30} color="var(--secondary)" title="emoji" />
        </span>
      </div>
      <div className=" flex-grow-1 w-100">
        <textarea
          ref={inputRef}
          className="footer-input w-100"
          placeholder="Type a message here..."
          onChange={(e) => setMessage(e?.target?.value)}
          onKeyDown={handleKeyDown}
          autoFocus
          id="inputID"
          rows={message.length > 246 ? 3 : message.length > 123 ? 2 : 1}
        />
      </div>

      {inputRef?.current && inputRef.current.value.trim() ? (
        <span
          className={`ms-4 cursor-pointer send-btn ${
            !inputRef.current.value.trim() ? "hidden" : ""
          }`}
          onClick={sendMessage}
          title="send message"
        >
          <ChatSendBtn size="40px" />
        </span>
      ) : (
        <span
          className={`ms-4 cursor-pointer dummy-width-audio ${
            inputRef?.current?.value ? "hidden" : ""
          }`}
          onClick={() => setAudioRecordshow(true)}
        >
          <IoMdMic size={30} color="var(--secondary)" title="Audio Recording" />
        </span>
      )}

      {chatContext?.isPreview && (
        <PreviewPage
          attachments={chatContext?.attachment}
          onSend={() => onSendAttachment()}
          type="image"
          setMessage={setMessage}
          message={message}
        />
      )}
      {replyshow && <ReplyMessage setReplyshow={setReplyshow} />}

      {forwardshow && (
        <ForwardMessage
          setForwardshow={setForwardshow}
          setModalShow={setModalShow}
          setShow={setShow}
          chatContext={chatContext}
        />
      )}
      {showMaps && <GoogleMaps setShowMaps={setShowMaps} />}
    </div>
  );
}

const ReplyMessage = ({ setReplyshow }: { setReplyshow: Function }) => {
  const chatContext = useChatContext();
  const userContext = useUserContext();

  const repliedSubMessage =
    chatContext?.repliedMessage?.message?.length > 300
      ? chatContext?.repliedMessage?.message?.substring(0, 300) + "....."
      : chatContext?.repliedMessage?.message;
  return (
    <div className="reply-msg p-3 mb-2 d-flex  align-items-center">
      <div className="reply-box flex-grow-1 py-2 px-3 d-flex justify-content-between">
        <div>
          <div className="reply-name ">
            {chatContext?.repliedMessage?.from?._id === userContext?.user?.id
              ? "You"
              : chatContext?.repliedMessage?.from?.name}
          </div>
          <div className="reply-subtext text-break">{repliedSubMessage}</div>
        </div>

        {chatContext?.repliedMessage?.attachment_type === "IMAGE" && (
          <div className="msg-reply-image  cursor-pointer">
            <img src={chatContext?.repliedMessage?.attachment} alt="" />
          </div>
        )}
        {chatContext?.repliedMessage?.attachment_type === "VIDEO" && (
          <span>
            <FaVideo
              size={20}
              color="var(--secondary)"
              title="replied a video"
            />
          </span>
        )}
        {chatContext?.repliedMessage?.attachment_type === "AUDIO" && (
          <span>
            <AiFillAudio
              size={20}
              color="var(--secondary)"
              title="replied a audio"
            />
          </span>
        )}
        {chatContext?.repliedMessage?.attachment_type === "FILE" && (
          <span>
            <IoMdDocument
              fontSize={20}
              color="var(--secondary)"
              title=" replied a document"
            />
          </span>
        )}
      </div>

      <span className="px-2 cursor-pointer" onClick={() => setReplyshow(false)}>
        <RxCross2
          size={24}
          color="var(--secondary)"
          title="Close the reply box"
          onClick={() => chatContext?.setRepliedMessage("")}
        />
      </span>
    </div>
  );
};

const ForwardMessage = ({
  setForwardshow,
  setModalShow,
  setShow,
  chatContext,
}: {
  setForwardshow: Function;
  setModalShow: Function;
  setShow: Function;
  chatContext: ChatContextProp;
}) => {
  const forwardhandle = () => {
    setShow(true);
    setModalShow(true);
  };

  const forwardDismissHandle = () => {
    setForwardshow(false);
    chatContext?.setForwardMessageCount([]);
    chatContext?.setSelectedMsgIds([]);
  };

  return (
    <div className="forward-msg p-3 d-flex  align-items-center">
      <span
        className="px-2 cursor-pointer"
        onClick={() => forwardDismissHandle()}
      >
        <RxCross2
          size={24}
          color="var(--secondary)"
          title="Close the forward box"
        />
      </span>
      <div className="reply-box flex-grow-1 py-2 px-3 d-flex  align-items-center justify-content-between">
        <div className="reply-name ">
          {" "}
          {chatContext?.forwardMessageCount?.length} selected
        </div>
        <div>
          {/* <span className=" px-2">
            <RiDeleteBin6Line
              size={24}
              color="var(--secondary)"
              title="Delete"
            />
          </span> */}
          {chatContext?.forwardMessageCount?.length > 0 && (
            <span onClick={() => forwardhandle()} className=" cursor-pointer">
              <RiShareForwardFill
                size={24}
                color="var(--secondary)"
                title="Forward"
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
