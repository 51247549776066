import { useEffect, useRef, useState } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useParams } from "react-router-dom";
import useChatRoom from "../../../common/hooks/useChatRoom";
import useNavigateToChat from "../../../common/hooks/useNavigateToChat";
import { useChatContext } from "../../../context/ChatProvider";
import { useUserContext } from "../../../context/UserProvider";
import RecordView from "../components/RecordView/RecordView";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import MessagesList from "../MessagesList/MessagesList";
import Sidebar from "../Sidebar/Sidebar";
import "./ChatPage.scss";
import ForwardPopup from "../forwardPopup/ForwardPopup";
import AudioAndVideo from "../AudioAndVideo/AudioAndVideo";
import ChatLoader from "../../ChatLoadingPage/ChatLoader";
import ProfileSection from "../ProfileSection/ProfileSection";
import IncomingCall from "../components/IncomingCall/IncomingCall";

export default function ChatPage() {
  const {
    activeInbox,
    handleShowIcon,
    isShowIcon,
    setShouldScrollToBottom,
    shouldScrollToBottom,
  } = useChatRoom();
  useNavigateToChat(activeInbox);
  const { id } = useParams();
  const chatContext = useChatContext();
  const [replyshow, setReplyshow] = useState(false);
  const [forwardshow, setForwardshow] = useState(false);
  const [forwardModalshow, setModalShow] = useState(false);
  const [show, setShow] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isResponsive, setisResponsive] = useState(false);
  const [second, setSecond] = useState<string>("00");
  const [minute, setMinute] = useState<string>("00");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);
  const [audioRecordshow, setAudioRecordshow] = useState(false);
  const userContext = useUserContext();
  const [search, setSearch] = useState<any>("");
  const [recordedUrl, setRecordedUrl] = useState("");

  const mediaStream: any = useRef<any>(null);
  const mediaRecorder = useRef<any>(null);
  const chunks = useRef<any>([]);
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        const computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter.toString();
        const computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter.toString();

        setSecond(computedSecond);
        setMinute(computedMinute);

        setCounter((prevCounter) => prevCounter + 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter]);

  const handleStartRecording = async () => {
    setRecordedUrl("");
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStream.current = stream;
      mediaRecorder.current = new MediaRecorder(stream);
      mediaRecorder.current.ondataavailable = (e: any) => {
        if (e.data.size > 0) {
          chunks.current.push(e?.data);
        }
      };
      mediaRecorder.current.onstop = () => {
        const recordedBlob = new Blob(chunks.current, { type: "audio/webm" });
        const url = URL.createObjectURL(recordedBlob);
        setRecordedUrl(url);
        chunks.current = [];
      };
      mediaRecorder.current.start();
    } catch (error: any) {
      alert("Notaccessed");
      console.error("Error accessing microphone:", error);
      window.prompt(`Error accessing microphone: ${error?.message}`);
    }
  };
  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.stop();
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track: any) => track.stop());
    }
  };

  const listIndividualConversation = async () => {
    await chatContext.getMessagesByConversationID(id, {
      search: search,
      limit: chatContext?.messageLimit,
    });
  };

  useEffect(() => {
    id && listIndividualConversation();
  }, [
    chatContext.getMessagesByConversationID,
    id,
    search,
    chatContext?.messageLimit,
  ]);

  useEffect(() => {
    setShouldScrollToBottom(true);
    setReplyshow(false);
    setForwardshow(false);
  }, [id]);

  return (
    <div className=" chat-layout">
      <div className="layout-content">
        {chatContext?.isLoading && <ChatLoader />}

        <Sidebar
          setisResponsive={setisResponsive}
          isResponsive={isResponsive}
        />

        <div
          className={`d-flex position-relative ${
            isTablet || isMobile ? (isResponsive ? "d-block" : "d-none") : ""
          } 
          `}
        >
          {isMobile && chatContext?.callRequested && <IncomingCall />}

          <div className="chat-body d-flex flex-column position-relative">
            <Header
              search={search}
              setSearch={setSearch}
              title={
                chatContext?.activeChat?.group_id?.group_name ||
                chatContext?.activeChat?.participants.find(
                  (item: any) => item._id !== userContext?.user?.id
                ).name
              }
              image={
                chatContext?.activeChat?.participants.find(
                  (item: any) => item?._id !== userContext?.user?.id
                )?.avatar || ""
              }
              setIsProfileOpen={setIsProfileOpen}
              isResponsive={isResponsive}
              setisResponsive={setisResponsive}
              opponentId={
                chatContext?.meta?.participants?.participants.find(
                  (item: any) => item?._id !== userContext?.user?.id
                )?.id
              }
              groupId={chatContext?.activeChat?.group_id}
            />
            <MessagesList
              onShowBottomIcon={handleShowIcon}
              shouldScrollToBottom={shouldScrollToBottom}
              replyshow={replyshow}
              setReplyshow={setReplyshow}
              forwardshow={forwardshow}
              setForwardshow={setForwardshow}
              mediaBlobUrl={recordedUrl || ""}
              search={search}
            />

            <div className="footer-container d-flex justify-content-center align-items-center position-relative">
              {isShowIcon && !replyshow && (
                <div
                  className="ScrollButton"
                  onClick={() => setShouldScrollToBottom(true)}
                >
                  <MdKeyboardArrowDown
                    size={30}
                    title="scroll down"
                    color="var(--primary)"
                  />
                </div>
              )}

              <Footer
                setReplyshow={setReplyshow}
                replyshow={replyshow}
                forwardshow={forwardshow}
                setForwardshow={setForwardshow}
                setShow={setShow}
                setModalShow={setModalShow}
                setAudioRecordshow={setAudioRecordshow}
                audioRecordshow={audioRecordshow}
                setShouldScrollToBottom={setShouldScrollToBottom}
              />
            </div>
            {audioRecordshow && (
              <div>
                <RecordView
                  setAudioRecordshow={setAudioRecordshow}
                  handleStartRecording={handleStartRecording}
                  stopRecording={stopRecording}
                  recordedUrl={recordedUrl}
                  setRecordedUrl={setRecordedUrl}
                />
              </div>
            )}
            {forwardModalshow && (
              <ForwardPopup
                show={show}
                setShow={setShow}
                forwardModalshow={forwardModalshow}
                setForwardshow={setForwardshow}
              />
            )}
            {(chatContext?.mode === "video" ||
              chatContext?.mode === "audio") && <AudioAndVideo />}
          </div>

          {isProfileOpen && (
            <ProfileSection
              name={
                chatContext?.activeChat?.group_id?.group_name ||
                chatContext?.activeChat?.participants.find(
                  (item: any) => item._id !== userContext?.user?.id
                ).name
              }
              image={
                chatContext?.activeChat?.participants.find(
                  (item: any) => item?.id !== userContext?.user?.id
                )?.avatar || ""
              }
              setIsProfileOpen={setIsProfileOpen}
              isProfileOpen={isProfileOpen}
              groupMembers={chatContext?.activeChat?.participants}
              groupId={chatContext?.activeChat?.group_id}
            />
          )}
        </div>
      </div>
    </div>
  );
}
