import {
  ErrorMessage,
  Field,
  FieldProps,
  FormikProvider,
  useFormik,
} from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Form, FormGroup, Row } from "react-bootstrap";
import { isMobile, isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import DatePicker from "react-multi-date-picker";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import VideoPlayer from "react-video-player-extended";
import * as Yup from "yup";
import { routes } from "../../constants/routes";
import AudioUpload from "../../components/Audiofile/AudioUpload";
import CommonButton from "../../components/CommonButton/CommonButton";
import CommonAsyncSelect from "../../components/CommonComponents/CommonAsyncSelect";
import ReactCustomSelect from "../../components/CommonComponents/ReactCustomSelect";
import FormikField from "../../components/FormikField/FormikField";
import CustomInputField from "../../components/InputField/CustomInputField";
import DeleteBlockToast from "../../components/PopupToast/DeleteBlockToast";
import ImagePopUp from "../../components/PopupToast/ImagePopUp";
import Modal from "../../components/PopupToast/Modal";
import SuccessErrorToast from "../../components/PopupToast/SuccessErrorToast";
import ToastPopup from "../../components/PopupToast/ToastPopup";
import ToastPopupSecond from "../../components/PopupToast/ToastPopupSecond";

import camera from "../../assets/camera.png";
import mic from "../../assets/mic.png";
import video from "../../assets/video.png";
import x from "../../assets/xo.png";
import { useChatContext } from "../livikit/context/ChatProvider";
import { group } from "console";

let timedPopupSetTimeOut: any = undefined;

const CreateNewGroupForm = ({ context, userContext }: any) => {
  const urlParams = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [buttonPopup, setButtonPopup] = useState(false);
  const [deleteAttachment, setDeleteAttachment] = useState(false);
  const [currentAttachment, setCurrentAttachment] = useState<any>({});
  const [deleteAttachmentVideo, setDeleteAttachmentVideo] = useState(false);
  const [currentAttachmentVideo, setCurrentAttachmentVideo] = useState<any>({});
  const [currentAttachmentSubmittedVideo, setCurrentAttachmentSubmittedVideo] =
    useState<any>({});
  const [bodyMessage, setBodyMessage] = useState("");
  const [timedPopup, setTimedPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [deleteAttachmentAudio, setDeleteAttachmentAudio] =
    useState<any>(false);
  const [currentAttachmentAudio, setCurrentAttachmentAudio] = useState<any>({});
  const [headerMessage, setHeaderMessage] = useState<any>("");
  const [imageShow, setImageShow] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [textMessage, setTextMessage] = useState<any>("");
  const [conformationPopUp, setConformationPopUp] = useState(false);
  const { t, i18n } = useTranslation();
  const [billBoardList, setBillBoardList] = useState<any>([]);
  const [assigneeList, setAssigneeList] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const [masterBillBoardList, setMasterBillBoardList] = useState<any>([]);

  const [cityList, setCityList] = useState<any>([]);
  const chatContext = useChatContext();
  const priority = [
    { value: "HIGH", label: `${t("HIGH")}` },
    { value: "MEDIUM", label: `${t("MEDIUM")}` },
    { value: "LOW", label: `${t("LOW")}` },
  ];

  useEffect(() => {
    if (urlParams?.cleaningId) {
      getCleaningDetail();
    }

    if (!(urlParams?.cleaningId || urlParams?.ticketId)) {
      getMasterBillBoardDetails();
      cityData();
    }

    if (
      !urlParams?.cleaningId ||
      (urlParams?.cleaningId && urlParams?.ticketId)
    ) {
      getUserDetails();
    }
  }, []);

  useEffect(() => {
    if (urlParams?.cleaningId && urlParams?.ticketId) {
      getTicketDetail();
    }
  }, [urlParams?.ticketId]);

  // new
  const getMasterBillBoardDetails = async () => {
    let data = await context?.loadMasterBillBoardsDetails();
    let res = data.map((d: any) => {
      return {
        label: d.billboard_type,
        value: d.billboard_type,
        dryDuration: d.dry_clean,
        wetDuration: d.wet_clean,
      };
    });
    setMasterBillBoardList(res);
  };

  const cityData = async () => {
    const data = await context?.cityList({ city: "" });
    if (data?.length) {
      let res = data?.map((d: any) => {
        return {
          label: d.city,
          value: d.city,
        };
      });
      setCityList(res);
    }
  };

  const getUserDetails = async () => {
    const { success, user } = await context?.loadMembers({
      status: "ACTIVE",
      department: "CLEANING",
      sort: "name",
      role: "TECHNICIAN",
    });
    if (success) {
      let res = user.map((d: any) => {
        return {
          label: d.name,
          value: d.id,
        };
      });
      setAssigneeList(res);
    }
  };

  const getCleaningDetail = async () => {
    if (
      urlParams?.cleaningId !== "0" &&
      urlParams?.cleaningId !== context?.cleaning?.id
    ) {
      const { success, cleaning } = await context?.getCleaningDetail(
        urlParams.cleaningId
      );
    }
  };

  //

  const getTicketDetail = async () => {
    if (urlParams?.ticketId !== "0") {
      const { success, ticket } = await context?.getTicketDetail(
        urlParams.ticketId
      );

      if (success) {
        // setDepartMentDetail(ticket?.department);
      }
    }
  };

  const handleUpdateData = async (values: any) => {
    setImageShow(false);

    values = {
      ...values,
      ticket_title: values?.title,
      billboards: values?.bill_board_values.map((option: any) => {
        return option?.id ? option?.id : option?.value;
      }),
      billboard_type: values?.bill_board_type,
      billboard_location: values?.bill_board_location,
      teamviewer_status: values?.bill_board_status,
      start_date: moment(values?.start_date).format("yyyy-MM-DD"),
      end_date: moment(values?.end_date).format("yyyy-MM-DD"),
    };
    if (urlParams?.ticketId) {
      values = {
        priority: values?.priority,
        assignee: values?.assignee,
        start_date: moment(values?.start_date).format("yyyy-MM-DD"),
        end_date: moment(values?.end_date).format("yyyy-MM-DD"),
      };
    }
    const { success, error } =
      urlParams?.cleaningId && !urlParams?.ticketId
        ? await context.updateCleaningDetail(urlParams.cleaningId, values)
        : urlParams?.cleaningId && urlParams?.ticketId
        ? await context.updateTicketDetail(urlParams.ticketId, values)
        : await context.createCleaningDetail(values);
    setIsSuccess(success);
    if (success) {
      timeOutPopUp(true);
      setTimedPopup(true);
      setBodyMessage(
        urlParams.cleaningId
          ? `${t("successError.update the Group successfully")}`
          : `${t("successError.create the group successfully")}`
      );
    } else {
      setTimedPopup(true);
      timeOutPopUp(false);
      setBodyMessage(error);
    }
  };

  const loadCleaningTicketData = async () => {
    const { tickets, success } = await context?.loadCleaningTickets(
      urlParams?.cleaningId,
      { sort: "-createdAt" }
    );
    if (success && tickets.length) {
      if (
        window.location.pathname.includes("/edit") &&
        urlParams?.ticketId !== "0"
      ) {
        navigate(
          routes.getCleaningTicketsIdUrl(
            urlParams.cleaningId,
            urlParams?.ticketId
          )
        );
      } else if (window.location.pathname.includes("/edit")) {
        navigate(
          routes.getCleaningTicketsIdUrl(urlParams.cleaningId, tickets[0].id)
        );
      }
    }
  };

  const timeOutPopUp = (value: any) => {
    timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        formik.resetForm();
        setBodyMessage("");
        setTimedPopup(false);
        if (
          headerMessage !== t("successError.cancel the changes") &&
          !location.pathname.includes("/tickets") &&
          location.pathname.includes("/cleaning")
        ) {
          navigate(routes.listCleaning);
        } else if (
          headerMessage !== t("successError.cancel the changes") &&
          location.pathname.includes("/cleaning") &&
          location.pathname.includes("/activities") &&
          urlParams?.ticketId
        ) {
          navigate(
            routes.getCleaningTicketsActivityFeedUrl(
              urlParams?.cleaningId,
              urlParams?.ticketId
            )
          );
        } else if (
          headerMessage !== t("successError.cancel the changes") &&
          location.pathname.includes("/cleaning") &&
          location.pathname.includes("/tickets") &&
          urlParams?.ticketId
        ) {
          loadCleaningTicketData();
          getTicketDetail();
        } else {
          setTimedPopup(false);
        }
      } else {
        setBodyMessage("");
        setTimedPopup(false);
      }
    }, 2000);
  };

  const cancelForm = () => {
    formik?.resetForm();
    setConformationPopUp(false);
  };

  const uploadImage = async (file: any) => {
    setLoading(true);
    let imageArray = [...formik?.values?.pictures];
    if (file?.length > 0) {
      for (let i = 0; i < file.length; i++) {
        const formData = new FormData();
        formData.append("file", file[i]);
        const data = await userContext?.uploadFiles(formData, "image");
        if (data?.success) {
          imageArray.push(data?.url);
        }
      }
      formik?.setFieldValue("pictures", [...imageArray]);
    }
    setLoading(false);
  };

  const getVideoUpload = async (file: any) => {
    setLoading(true);
    let videoArray = [...formik?.values?.videos];
    if (file?.length) {
      for (let i = 0; i < file.length; i++) {
        const formData = new FormData();
        formData.append("file", file[i]);
        const { success, url } = await userContext?.uploadFiles(
          formData,
          "video"
        );
        if (success) {
          videoArray.push(url);
        }
      }
      formik?.setFieldValue("videos", [...videoArray]);
    }
    setLoading(false);
  };

  const deleteImage = () => {
    setDeleteAttachment(false);
    setTimedPopup(true);
    setImageShow(false);
    setBodyMessage(`${t("successError.Image deleted successfully")}`);
    setIsSuccess(true);
    timeOutPopUp(false);
    let array = formik?.values?.pictures.filter(
      (item: any) => item !== currentAttachment?.file
    );
    formik?.setFieldValue("pictures", [...array]);
  };

  const deleteVideo = () => {
    setDeleteAttachmentVideo(false);
    setTimedPopup(true);
    setImageShow(false);
    setBodyMessage(`${t("successError.Video deleted successfully")}`);
    setIsSuccess(true);
    timeOutPopUp(false);
    let array = formik?.values?.videos.filter(
      (item: any) => item !== currentAttachmentVideo?.file
    );
    formik?.setFieldValue("videos", [...array]);
  };

  const deleteAudio = () => {
    setDeleteAttachmentAudio(false);
    setTimedPopup(true);
    setImageShow(false);
    setBodyMessage(`${t("successError.Audio deleted successfully")}`);
    setIsSuccess(true);
    timeOutPopUp(false);
    let array = formik?.values?.audios.filter(
      (item: any) => item !== currentAttachmentAudio?.file
    );
    formik?.setFieldValue("audios", [...array]);
  };

  const CleaningTitleOptions = [
    {
      label: "Dry Cleaning",
      value: "DRY CLEANING",
    },
    {
      label: "Wet Cleaning",
      value: "WET CLEANING",
    },
  ];

  const ScheduleOptions = [
    {
      label: "Automatic",
      value: "AUTOMATIC",
    },
    {
      label: "Manual",
      value: "MANUAL",
    },
  ];

  const StatusOptions = [
    {
      label: "Online",
      value: "online",
    },
    {
      label: "Offline",
      value: "offline",
    },
  ];

  interface Values {
    title: string;
    cleaning_type: string;
    schedule: string;
    duration: number;
    bill_board_type: string;
    bill_board_values: Array<any>;
    bill_board_status: string;
    bill_board_location: string;
    priority: string;
    assignee: string;
    assigneeName: string;
    start_date: string;
    end_date: string;
    description: string;
    pictures: Array<any>;
    videos: Array<any>;
    audios: Array<any>;
    submitted_reason: string;
  }

  let daysDifference = null;
  if (urlParams?.ticketId) {
    const endDate = moment(context?.ticket?.end_date);
    const startDate = moment(context?.ticket?.start_date);
    daysDifference = endDate.diff(startDate, "days");
  }

  const initialValues = {
    title: urlParams?.cleaningId ? context?.cleaning?.ticket_title : "",
    cleaning_type: urlParams?.cleaningId
      ? context?.cleaning?.cleaning_type
      : "",
    schedule: urlParams?.cleaningId ? context?.cleaning?.schedule : "",
    duration: urlParams?.ticketId
      ? daysDifference
      : urlParams?.cleaningId
      ? context?.cleaning?.duration
      : null,
    bill_board_type: urlParams?.cleaningId
      ? context?.cleaning?.billboard_type
      : "",

    bill_board_values: urlParams?.ticketId
      ? [context?.ticket?.bill_board_id?._id]
      : urlParams?.cleaningId
      ? context?.cleaning?.billboards
      : [],

    bill_board_location: urlParams?.cleaningId
      ? context?.cleaning?.billboard_location
      : "",
    bill_board_status: urlParams?.cleaningId
      ? context?.cleaning?.teamviewer_status
      : "",
    priority: urlParams?.ticketId
      ? context?.ticket?.priority
      : urlParams?.cleaningId
      ? context?.cleaning?.priority
      : "",
    assignee: urlParams?.ticketId
      ? context?.ticket?.assignee?._id
      : urlParams?.cleaningId
      ? context?.cleaning?.assignee?.id
      : "",
    assigneeName: urlParams?.ticketId
      ? context?.ticket?.assignee?.name
      : urlParams?.cleaningId
      ? context?.cleaning?.assignee?.name
      : "",
    start_date: urlParams?.ticketId
      ? context?.ticket?.start_date
      : urlParams?.cleaningId
      ? moment(context?.cleaning?.start_date).format("yyyy-MM-DD")
      : "",
    end_date: urlParams?.ticketId
      ? context?.ticket?.end_date
      : urlParams?.cleaningId
      ? moment(context?.cleaning?.end_date).format("yyyy-MM-DD")
      : "",
    description: urlParams?.ticketId
      ? context?.ticket?.description
      : urlParams?.cleaningId
      ? context?.cleaning?.description
      : "",
    group_name: urlParams?.cleaningId ? context?.cleaning?.group_name : "",

    pictures: urlParams?.ticketId ? context?.ticket?.pictures : [],
    videos: urlParams?.ticketId ? context?.ticket?.videos : [],
    audios: urlParams?.ticketId ? context?.ticket?.audios : [],

    submitted_reason: urlParams?.ticketId
      ? context?.ticket?.submitted_reason
      : "",
  };
  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    cleaning_type: Yup.string().required("Clening type is required"),
    schedule: Yup.string().required("Schedule is required"),
    duration: Yup.number().required("Duration is required"),
    bill_board_type: Yup.string().required("Billboard type is required"),
    bill_board_values: Yup.array()
      .min(1, "At least one billboard is required")
      .required("Billboards are required"),
    bill_board_location: Yup.string(),
    bill_board_status: Yup.string(),
    priority: Yup.string().required("Priority is required"),
    assignee: Yup.string().required("Assignee is required"),
    start_date: urlParams?.ticketId
      ? Yup.date().required("Start Date is required")
      : Yup.date().required("Start Date is required"),
    end_date: urlParams?.ticketId
      ? Yup.date()
          .required("End Date is required")
          .min(Yup.ref("start_date"), "End Date must be after Start Date")
      : Yup.date()
          .required("End Date is required")
          .min(Yup.ref("start_date"), "End Date must be after Start Date"),
    description: Yup.string()
      .min(2, "Too Short!")
      .max(1000)
      .required("Description is required"),
    group_name: Yup.string().required("Group Name is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      // handleUpdateData(values);
    },
  });

  const { errors, handleSubmit, isSubmitting, isValid, dirty, setSubmitting } =
    formik;

  useEffect(() => {
    if (!(urlParams?.cleaningId || urlParams?.ticketId)) {
      getBillBoardDetails();
    }
  }, [
    formik.values.bill_board_type,
    formik.values?.bill_board_location,
    formik.values?.bill_board_status,
  ]);

  const getBillBoardDetails = async () => {
    const { success, billboards } = await context?.loadBillBoards({
      board_type: formik.values.bill_board_type,
      city: formik.values?.bill_board_location
        ? formik.values?.bill_board_location
        : "",
      teamviewer_status: formik.values?.bill_board_status
        ? formik.values?.bill_board_status
        : "",
      sort: "board_no",
    });
    if (success) {
      let res = billboards.map((d: any) => {
        return {
          label: d.board_no,
          value: d.id,
        };
      });

      setBillBoardList(res);
    }
    return { success, billboards };
  };

  interface billboardAutocompleteProps {
    readonly label: string;
    readonly value: string;
  }

  const getBillboardOptions: any = async (
    inputValue: string,
    callback: (options: billboardAutocompleteProps) => void
  ) => {
    const { billboards } = await context?.loadBillBoards({
      board_type: formik.values.bill_board_type,
      city: formik.values?.bill_board_location,
      teamviewer_status: formik.values?.bill_board_status,
      search: inputValue,
      sort: "board_no",
      board_status: "ACTIVE",
    });

    callback(
      billboards.map((d: any) => ({
        label: d?.board_no,
        value: d?.id,
      }))
    );
  };

  const getMasterBillBoardOptions: any = async (
    inputValue: string,
    callback: (options: billboardAutocompleteProps) => void
  ) => {
    let data = await context?.loadMasterBillBoardsDetails({
      search: inputValue,
    });

    callback(
      data?.map((d: any) => ({
        label: d?.billboard_type,
        value: d?.billboard_type,
        dryDuration: d.dry_clean,
        wetDuration: d.wet_clean,
      }))
    );
  };

  const getCityOptions: any = async (
    inputValue: string,
    callback: (options: billboardAutocompleteProps) => void
  ) => {
    const data = await context?.cityList({
      city: inputValue,
    });
    callback(
      data?.map((d: any) => ({
        label: d?.city,
        value: d?.city,
      }))
    );
  };

  const reAssignTicket = async () => {
    const { success, error } = await context?.reassignTicket(
      urlParams?.ticketId,
      {
        assignee: formik.values.assignee,
        end_date: formik.values.end_date,
        department: "CLEANING",
      }
    );
    setIsSuccess(success);
    if (success) {
      setTimedPopup(true);
      setBodyMessage(`${t("successError.reassigned the ticket successfully")}`);
      timeOutPopUp(true);
    } else {
      setTimedPopup(true);
      setBodyMessage(error);
      timeOutPopUp(false);
    }
  };

  const approveTicket = async (value: any) => {
    const { success, error } = value
      ? await context?.approveBySupervisorTicket(
          urlParams?.ticketId,
          formik?.values?.submitted_reason
        )
      : await context?.approveByStaffTicket(urlParams?.ticketId);
    setIsSuccess(success);
    if (success) {
      setTimedPopup(true);
      timeOutPopUp(true);
      setBodyMessage(
        value
          ? `${t("successError.verified the ticket successfully")}`
          : `${t("successError.approved the ticket successfully")}`
      );
    } else {
      setTimedPopup(true);
      timeOutPopUp(false);
      setBodyMessage(error);
    }
  };

  const rejectTicket = async () => {
    const { success, error } = await context?.rejectTicket(urlParams?.ticketId);
    setIsSuccess(success);
    if (success) {
      setTimedPopup(true);
      timeOutPopUp(true);
      setBodyMessage(`${t("successError.rejected the ticket successfully")}`);
    } else {
      setTimedPopup(true);
      timeOutPopUp(false);
      setBodyMessage(error);
    }
  };

  const getAssigneeOptions: any = async (
    inputValue: string,
    callback: (options: billboardAutocompleteProps) => void
  ) => {
    const { users } = await context?.autoCompleteApi({
      department: "CLEANING",
      name: inputValue,
      role: "TECHNICIAN",
    });
    callback(
      users.map((d: any) => ({
        label: d?.name,
        value: d?.id,
      }))
    );
  };

  const formatedName = (name: string) => {
    return name?.length > 15 ? name.substring(0, 15) + "...." : name;
  };
  const createGroupConversation = async () => {
    const res = await chatContext?.createGroup({
      ticket_id: urlParams?.ticketId,
      group_name: context?.ticket?.ticket_no,
      group_member: [context?.ticket?.assignee],
    });
    if (res?.success) {
      navigate(`/chat/${res?.conversation._id}`);
      userContext?.setGroupConversationInfo(res?.data);
    }
  };
  return (
    <div className="">
      {!window.location.pathname.includes("/0/edit") && (
        <FormikProvider value={formik}>
          <Form validated={true} onSubmit={handleSubmit}>
            {/* main form */}
            <div className={`${isMobile ? " " : "p-5"}`}>
              <div>
                <Row>
                  <Col md={12} className=" formprofile mb-4">
                    <FormikField
                      name="group_name"
                      type="text"
                      validationSchema={validationSchema}
                      label={t("cleaning.GroupName")}
                      errors={errors as Record<string, string>}
                    />
                  </Col>
                  <Col md={6} className="formprofile mb-4">
                    <Field name="title">
                      {({ field }: FieldProps<string>) => {
                        return (
                          <CustomInputField
                            label={t("cleaning.Ticket Title")}
                            error={errors["title"] as string}
                            validationSchema={validationSchema}
                            inputId={""}
                            field={field}
                          >
                            <ReactCustomSelect
                              feildName={"title"}
                              className={"select-wrapper"}
                              defaultOptions={{
                                label: `${t(formik?.values?.title)}`,
                                value: formik?.values?.title,
                              }}
                              isMulti={false}
                              updateFieldValue={(option: any) => {
                                formik?.setFieldValue("title", option.value);
                                formik.setFieldValue("duration", null);
                                formik.setFieldValue("bill_board_type", "");
                                option.value === "DRY CLEANING"
                                  ? formik?.setFieldValue(
                                      "cleaning_type",
                                      "DRY"
                                    )
                                  : formik?.setFieldValue(
                                      "cleaning_type",
                                      "WET"
                                    );
                              }}
                              dataOptions={CleaningTitleOptions}
                              searchDisabled={false}
                              isFieldDisabled={
                                urlParams?.cleaningId || urlParams?.ticketId
                                  ? true
                                  : false
                              }
                            />
                          </CustomInputField>
                        );
                      }}
                    </Field>
                  </Col>

                  <Col md={6} className=" mb-4">
                    <FormikField
                      name="cleaning_type"
                      type="text"
                      disabled
                      validationSchema={validationSchema}
                      label={t("cleaning.Cleaning Type")}
                      errors={errors as Record<string, string>}
                    />
                  </Col>

                  <Col md={6} className="formprofile mb-4">
                    <Field name="schedule">
                      {({ field }: FieldProps<string>) => {
                        return (
                          <CustomInputField
                            label={t("cleaning.Schedule")}
                            error={errors["schedule"] as string}
                            validationSchema={validationSchema}
                            inputId={""}
                            field={field}
                          >
                            <ReactCustomSelect
                              isFieldDisabled={
                                urlParams?.cleaningId || urlParams?.ticketId
                                  ? true
                                  : false
                              }
                              className={"select-wrapper"}
                              feildName={"schedule"}
                              defaultOptions={{
                                label: `${t(formik?.values?.schedule)}`,
                                value: formik?.values?.schedule,
                              }}
                              isMulti={false}
                              updateFieldValue={(option: any) => {
                                formik?.setFieldValue("schedule", option.value);
                                formik.setFieldValue("bill_board_type", "");
                                formik.setFieldValue("duration", null);
                                formik?.setFieldValue("bill_board_values", []);
                                formik.setFieldValue("start_date", "");
                                formik.setFieldValue("end_date", "");
                              }}
                              dataOptions={ScheduleOptions}
                              searchDisabled={false}
                            />
                          </CustomInputField>
                        );
                      }}
                    </Field>
                  </Col>

                  <Col md={6} className="formprofile mb-4">
                    <Field name="bill_board_type">
                      {({ field }: FieldProps<string>) => {
                        return (
                          <CustomInputField
                            label={t("cleaning.Billboard Type")}
                            error={errors["bill_board_type"] as string}
                            validationSchema={validationSchema}
                            inputId={""}
                            field={field}
                          >
                            <CommonAsyncSelect
                              feildName={"bill_board_type"}
                              className={"select-wrapper"}
                              isMulti={false}
                              isClearable={false}
                              values={{
                                label: `${t(formik?.values?.bill_board_type)}`,
                                value: formik?.values?.bill_board_type,
                              }}
                              options={masterBillBoardList}
                              cacheOptions
                              loadOptionValue={getMasterBillBoardOptions}
                              updateField={(option: any) => {
                                formik?.setFieldValue(
                                  "bill_board_type",
                                  option.value
                                );
                                formik?.setFieldValue("bill_board_values", []);
                                if (formik?.values?.title?.includes("DRY")) {
                                  formik.setFieldValue(
                                    "duration",
                                    option?.dryDuration
                                  );
                                } else {
                                  formik.setFieldValue(
                                    "duration",
                                    option?.wetDuration
                                  );
                                }
                              }}
                              isFieldDisabled={
                                formik.values.cleaning_type === "" ||
                                urlParams?.cleaningId ||
                                urlParams?.ticketId
                                  ? true
                                  : false
                              }
                            />
                          </CustomInputField>
                        );
                      }}
                    </Field>
                  </Col>

                  <Col md={6} className="formprofile">
                    <FormikField
                      name={"duration"}
                      label={t("cleaning.Duration (in days)")}
                      disabled={
                        formik.values.schedule !== "MANUAL" ||
                        (urlParams?.cleaningId &&
                          !window.location.pathname.includes(`/tickets`)) ||
                        (urlParams?.ticketId
                          ? !(
                              context?.ticket?.status === "CREATED" ||
                              context?.ticket?.status === "VIEWED" ||
                              context?.ticket?.status === "REASSIGNED"
                            ) ||
                            ((context?.ticket?.status === "CREATED" ||
                              context?.ticket?.status === "VIEWED") &&
                              moment(new Date())
                                .add(1, "day")
                                .format("DD/MM/yyyy") ===
                                moment(context?.ticket?.end_date).format(
                                  "DD/MM/yyyy"
                                ))
                          : false)
                      }
                      errors={errors as any}
                      validationSchema={validationSchema}
                      type={"number"}
                    />
                  </Col>

                  <Col md={6} className="formprofile mb-4">
                    <Field name="bill_board_location">
                      {({ field }: FieldProps<string>) => {
                        return (
                          <CustomInputField
                            label={t("cleaning.Billboard City")}
                            error={errors["bill_board_location"] as string}
                            validationSchema={validationSchema}
                            inputId={""}
                            field={field}
                          >
                            <CommonAsyncSelect
                              className={"select-wrapper"}
                              isMulti={false}
                              isClearable={
                                formik.values.bill_board_location ? true : false
                              }
                              values={{
                                label: formik.values.bill_board_location,
                                value: formik.values.bill_board_location,
                              }}
                              options={cityList}
                              cacheOptions
                              loadOptionValue={getCityOptions}
                              updateField={(option: any) => {
                                formik.setFieldValue(
                                  "bill_board_location",
                                  option?.value
                                );
                                formik?.setFieldValue("bill_board_values", []);
                              }}
                              isFieldDisabled={
                                urlParams?.cleaningId || urlParams?.ticketId
                                  ? true
                                  : false
                              }
                            />
                          </CustomInputField>
                        );
                      }}
                    </Field>
                  </Col>

                  <Col md={6} className="formprofile mb-4">
                    <Field name="bill_board_status">
                      {({ field }: FieldProps<string>) => {
                        return (
                          <CustomInputField
                            label={t("cleaning.Billboard Status (Team Viewer)")}
                            error={errors["bill_board_status"] as string}
                            validationSchema={validationSchema}
                            inputId={""}
                            field={field}
                          >
                            <ReactCustomSelect
                              className={"select-wrapper"}
                              feildName={"bill_board_status"}
                              defaultOptions={{
                                label: `${t(
                                  formik?.values?.bill_board_status
                                )}`,
                                value: formik?.values?.bill_board_status,
                              }}
                              isMulti={false}
                              updateFieldValue={(option: any) => {
                                if (option) {
                                  formik?.setFieldValue(
                                    "bill_board_status",
                                    option.value
                                  );
                                } else {
                                  formik?.setFieldValue(
                                    "bill_board_status",
                                    ""
                                  );
                                }
                                formik?.setFieldValue("bill_board_values", []);
                              }}
                              dataOptions={StatusOptions}
                              searchDisabled={false}
                              isClearable={false}
                              isFieldDisabled={
                                urlParams?.cleaningId || urlParams?.ticketId
                                  ? true
                                  : false
                              }
                            />
                          </CustomInputField>
                        );
                      }}
                    </Field>
                  </Col>

                  <Col md={6} className="formprofile mb-4">
                    <Field name="bill_board_values">
                      {({ field }: FieldProps<string>) => {
                        return (
                          <CustomInputField
                            label={t("cleaning.Billboards")}
                            error={errors["bill_board_values"] as string}
                            validationSchema={validationSchema}
                            inputId={""}
                            field={field}
                          >
                            <CommonAsyncSelect
                              values={
                                urlParams?.ticketId
                                  ? {
                                      label:
                                        context?.ticket?.bill_board_id
                                          ?.board_no,
                                      value:
                                        context?.ticket?.bill_board_id?._id,
                                    }
                                  : urlParams?.cleaningId
                                  ? formik?.values?.bill_board_values?.map(
                                      (d: any) => ({
                                        label: d?.board_no,
                                        value: d?._id,
                                      })
                                    )
                                  : formik?.values?.bill_board_values?.map(
                                      (d: any) => ({
                                        label: d?.label,
                                        value: d?.value,
                                      })
                                    )
                              }
                              isClearable={false}
                              className={"select-wrapper"}
                              isMulti={urlParams?.ticketId ? false : true}
                              options={billBoardList}
                              cacheOptions={false}
                              loadOptionValue={getBillboardOptions}
                              updateField={(selectedOptions: any) => {
                                formik?.setFieldValue(
                                  "bill_board_values",
                                  selectedOptions
                                );
                              }}
                              isFieldDisabled={
                                formik.values.bill_board_type === "" ||
                                (urlParams?.cleaningId || urlParams?.ticketId
                                  ? true
                                  : false)
                              }
                            />
                          </CustomInputField>
                        );
                      }}
                    </Field>
                  </Col>

                  <Col md={6} className="formprofile mb-4">
                    <Field name="priority">
                      {({ field }: FieldProps<string>) => {
                        return (
                          <CustomInputField
                            label={t("cleaning.Priority")}
                            error={errors["priority"] as string}
                            validationSchema={validationSchema}
                            inputId={""}
                            field={field}
                          >
                            <ReactCustomSelect
                              isFieldDisabled={
                                (urlParams?.cleaningId &&
                                  !window.location.pathname.includes(
                                    `/tickets`
                                  )) ||
                                (urlParams?.ticketId
                                  ? !(
                                      context?.ticket?.status === "CREATED" ||
                                      context?.ticket?.status === "VIEWED" ||
                                      context?.ticket?.status === "REASSIGNED"
                                    ) ||
                                    ((context?.ticket?.status === "CREATED" ||
                                      context?.ticket?.status === "VIEWED") &&
                                      moment(new Date())
                                        .add(1, "day")
                                        .format("DD/MM/yyyy") ===
                                        moment(
                                          context?.ticket?.end_date
                                        ).format("DD/MM/yyyy"))
                                  : false)
                              }
                              className={"select-wrapper"}
                              feildName={"priority"}
                              defaultOptions={{
                                label: `${t(formik?.values?.priority)}`,
                                value: formik?.values?.priority,
                              }}
                              isMulti={false}
                              updateFieldValue={(option: any) =>
                                formik?.setFieldValue("priority", option.value)
                              }
                              dataOptions={priority}
                              searchDisabled={false}
                            />
                          </CustomInputField>
                        );
                      }}
                    </Field>
                  </Col>

                  <Col md={6} className="formprofile mb-4">
                    <Field name="assignee">
                      {({ field }: FieldProps<string>) => {
                        return (
                          <CustomInputField
                            label={t("cleaning.Assignee")}
                            error={errors["assignee"] as string}
                            validationSchema={validationSchema}
                            inputId={""}
                            field={field}
                          >
                            <CommonAsyncSelect
                              className={"select-wrapper"}
                              isClearable={false}
                              isFieldDisabled={
                                (urlParams?.cleaningId &&
                                  !window.location.pathname.includes(
                                    `/tickets`
                                  )) ||
                                (urlParams?.ticketId
                                  ? context?.ticket?.status === "CLOSED" ||
                                    ((context?.ticket?.status === "CREATED" ||
                                      context?.ticket?.status === "VIEWED") &&
                                      moment(new Date())
                                        .add(1, "day")
                                        .format("DD/MM/yyyy") ===
                                        moment(
                                          context?.ticket?.end_date
                                        ).format("DD/MM/yyyy"))
                                  : false)
                              }
                              isMulti={false}
                              values={{
                                label: formik?.values?.assigneeName,
                                value: formik.values.assignee,
                              }}
                              options={assigneeList}
                              cacheOptions
                              loadOptionValue={getAssigneeOptions}
                              updateField={(option: any) => {
                                formik?.setErrors({});

                                formik?.setFieldValue("assignee", option.value);
                                formik?.setFieldValue(
                                  "assigneeName",
                                  option.label
                                );
                                formik?.setErrors({});
                              }}
                            />
                          </CustomInputField>
                        );
                      }}
                    </Field>
                  </Col>

                  <Col md={6} className="formprofile mb-4">
                    <FormGroup
                      className="mt-4 position-relative"
                      style={{ color: "#2a2b2f" }}
                    >
                      <label>
                        {t("cleaning.Start Date")}
                        <span className="">*</span>
                      </label>
                      <DatePicker
                        editable={false}
                        placeholder="DD-MM-YYYY"
                        // disabled={
                        //   !formik?.values?.duration ||
                        //   (urlParams?.cleaningId &&
                        //     !window.location.pathname.includes(
                        //       `/cleaning/${urlParams?.cleaningId}/tickets`
                        //     )) ||
                        //   (urlParams?.ticketId
                        //     ? !(
                        //         context?.ticket?.status === "CREATED" ||
                        //         context?.ticket?.status === "VIEWED" ||
                        //         context?.ticket?.status === "REASSIGNED"
                        //       ) ||
                        //       ((context?.ticket?.status === "CREATED" ||
                        //         context?.ticket?.status === "VIEWED") &&
                        //         moment(new Date())
                        //           .add(1, "day")
                        //           .format("DD/MM/yyyy") ===
                        //           moment(context?.ticket?.end_date).format(
                        //             "DD/MM/yyyy"
                        //           ))
                        //     : false)
                        // }
                        name={"start_date"}
                        value={new Date(formik?.values?.start_date)}
                        minDate={moment(new Date()).toDate()}
                        className={`${
                          (urlParams?.ticketId
                            ? !(
                                context?.ticket?.status === "CREATED" ||
                                context?.ticket?.status === "VIEWED" ||
                                context?.ticket?.status === "REASSIGNED"
                              )
                            : false) && "disabled-field"
                        } form-control`}
                        id={i18n.language === "en" ? "" : "ArabicDate"}
                        format="DD-MM-YYYY"
                        onChange={(e: any) => {
                          formik.setFieldValue(
                            "start_date",
                            moment.unix(e?.unix).format("yyyy-MM-DD")
                          );
                          let EndDate = moment(moment.unix(e?.unix))
                            .add(formik?.values?.duration, "day")
                            .format("yyyy-MM-DD");

                          formik.setFieldValue("end_date", EndDate);
                        }}
                      />

                      <Form.Control.Feedback
                        style={{
                          display: !!errors.start_date ? "block" : "none",
                        }}
                        type={!!errors.start_date ? "invalid" : "valid"}
                      >
                        <ErrorMessage name="start_date" />
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                  <Col md={6} className="formprofile mb-4">
                    <FormGroup className="mt-4 position-relative">
                      <label>
                        {t("cleaning.End Date")}
                        <span className="">*</span>
                      </label>
                      <DatePicker
                        editable={false}
                        placeholder="DD-MM-YYYY"
                        disabled={urlParams?.cleaningId ? true : false}
                        name={"end_date"}
                        format="DD-MM-YYYY"
                        value={new Date(formik?.values?.end_date)}
                        minDate={moment(formik?.values?.start_date).toDate()}
                        className={`${
                          !(context?.ticket?.status !== "CLOSED") &&
                          "disabled-field"
                        } form-control`}
                        onChange={(e: any) => {
                          formik.setFieldValue(
                            "end_date",
                            moment.unix(e?.unix).format("yyyy-MM-DD")
                          );
                        }}
                      />
                      <Form.Control.Feedback
                        style={{
                          display: !!errors.end_date ? "block" : "none",
                        }}
                        type={!!errors.end_date ? "invalid" : "valid"}
                      >
                        <ErrorMessage name="end_date" />
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>

                  <Col md={12} className="mb-4">
                    <FormikField
                      name="description"
                      type="text"
                      as="textarea"
                      validationSchema={validationSchema}
                      label={t("cleaning.Description")}
                      errors={errors as Record<string, string>}
                      disabled={
                        (urlParams?.cleaningId &&
                          !window.location.pathname.includes(
                            `/cleaning/${urlParams?.cleaningId}/tickets`
                          )) ||
                        (urlParams?.ticketId
                          ? !(
                              context?.ticket?.status === "CREATED" ||
                              context?.ticket?.status === "VIEWED" ||
                              context?.ticket?.status === "REASSIGNED"
                            )
                          : false)
                      }
                    />
                  </Col>

                  {urlParams?.ticketId &&
                    context?.ticket?.submitted_at?.length > 0 && (
                      <Col md={12} className="mb-4">
                        <FormikField
                          name="submitted_reason"
                          type="text"
                          as="textarea"
                          validationSchema={validationSchema}
                          label={t("new_tickets.Submit Reason")}
                          errors={errors as Record<string, string>}
                          disabled={context?.ticket?.status !== "SUBMITTED"}
                        />
                      </Col>
                    )}
                </Row>

                {urlParams?.ticketId && (
                  <div className=" d-flex mt-4 flex-wrap ">
                    {context?.ticket?.createdAt?.length > 0 && (
                      <div
                        className=" px-4 border py-3  mt-3 me-3"
                        style={{ width: "165px" }}
                      >
                        <div>
                          <FormGroup>
                            <label>{"Created By"} </label>
                            <div className="text-wrap flex-wrap">
                              {formatedName(context?.ticket?.created_by?.name)}
                            </div>
                          </FormGroup>
                        </div>
                        <div>
                          <FormGroup className="mt-4">
                            <label>{"Created On"} </label>
                            <div>
                              <Moment format="DD/MM/yyyy">
                                {context?.ticket?.createdAt}
                              </Moment>
                            </div>
                          </FormGroup>
                        </div>
                      </div>
                    )}
                    {context?.ticket?.viewed_on?.length > 0 && (
                      <div
                        className=" px-4 border py-3  mt-3 me-3"
                        style={{ width: "165px" }}
                      >
                        <div>
                          <FormGroup>
                            <label>{"Viewed By"} </label>
                            <div>{context?.ticket?.assignee?.name}</div>
                          </FormGroup>
                        </div>
                        <div>
                          <FormGroup className="mt-4">
                            <label>{"Viewed On"} </label>
                            <div>
                              <Moment format="DD/MM/yyyy">
                                {context?.ticket?.viewed_on}
                              </Moment>
                            </div>
                          </FormGroup>
                        </div>
                      </div>
                    )}
                    {context?.ticket?.submitted_at?.length > 0 && (
                      <div
                        className=" px-4 border py-3  mt-3 me-3"
                        style={{ width: "165px" }}
                      >
                        <div>
                          <FormGroup>
                            <label>{"Sumbitted By"} </label>
                            <div>{context?.ticket?.submitted_by?.name}</div>
                          </FormGroup>
                        </div>
                        <div>
                          <FormGroup className="mt-4">
                            <label>{"Sumbitted On"} </label>
                            <div>
                              <Moment format="DD/MM/yyyy">
                                {context?.ticket?.submitted_at}
                              </Moment>
                            </div>
                          </FormGroup>
                        </div>
                      </div>
                    )}

                    {context?.ticket?.approved_on_supervisor?.length > 0 && (
                      <div
                        className=" px-4 border py-3  me-3 mt-3"
                        style={{ width: "165px" }}
                      >
                        <div>
                          <FormGroup>
                            <label>{"Verified By"} </label>
                            <div>
                              {context?.ticket?.approved_by_supervisor?.name}
                            </div>
                          </FormGroup>
                        </div>
                        <div>
                          <FormGroup className="mt-4">
                            <label>{"Verified On"} </label>
                            <div>
                              <Moment format="DD/MM/yyyy">
                                {context?.ticket?.approved_on_supervisor}
                              </Moment>
                            </div>
                          </FormGroup>
                        </div>
                      </div>
                    )}
                    {context?.ticket?.reassigned_on?.length > 0 && (
                      <div
                        className=" px-4 border py-3  me-3 mt-3"
                        style={{ width: "165px" }}
                      >
                        <div>
                          <FormGroup>
                            <label>{"Reassigned By"} </label>
                            <div>
                              {context?.ticket?.reassigned_by?.name || "-"}
                            </div>
                          </FormGroup>
                        </div>
                        <div>
                          <FormGroup className="mt-4">
                            <label>{"Reassigned On"} </label>
                            <div>
                              <Moment format="DD/MM/yyyy">
                                {context?.ticket?.reassigned_by?.confirmed_at}
                              </Moment>
                            </div>
                          </FormGroup>
                        </div>
                      </div>
                    )}
                    {context?.ticket?.rejected_at?.length > 0 && (
                      <div
                        className=" px-4 border py-3  me-3 mt-3"
                        style={{ width: "165px" }}
                      >
                        <div>
                          <FormGroup>
                            <label>{"Rejected By"} </label>
                            <div>
                              {context?.ticket?.rejected_by?.name || "-"}
                            </div>
                          </FormGroup>
                        </div>
                        <div>
                          <FormGroup className="mt-4">
                            <label>{"Rejected On"} </label>
                            <div>
                              <Moment format="DD/MM/yyyy">
                                {context?.ticket?.rejected_at}
                              </Moment>
                            </div>
                          </FormGroup>
                        </div>
                      </div>
                    )}
                    {context?.ticket?.approved_on?.length > 0 && (
                      <div
                        className=" px-4 border py-3  me-3 mt-3"
                        style={{ width: "165px" }}
                      >
                        <div>
                          <FormGroup>
                            <label>{"Aprroved By"} </label>
                            <div>
                              {context?.ticket?.approved_by_manager?.name}
                            </div>
                          </FormGroup>
                        </div>
                        <div>
                          <FormGroup className="mt-4">
                            <label>{"Aprroved On"} </label>
                            <div>
                              <Moment format="DD/MM/yyyy">
                                {context?.ticket?.approved_on}
                              </Moment>
                            </div>
                          </FormGroup>
                        </div>
                      </div>
                    )}
                    {context?.ticket?.status === "CLOSED" && (
                      <div
                        className=" px-4 border py-3  me-3 mt-3"
                        style={{ width: "165px" }}
                      >
                        <div>
                          <FormGroup>
                            <label>{"Closed By"} </label>
                            <div>
                              {context?.ticket?.approved_on
                                ? context?.ticket?.approved_by_manager?.name
                                : context?.ticket?.rejected_by?.name}
                            </div>
                          </FormGroup>
                        </div>
                        <div>
                          <FormGroup className="mt-4">
                            <label>{"Closed On"} </label>
                            <div>
                              <Moment format="DD/MM/yyyy">
                                {context?.ticket?.approved_on
                                  ? context?.ticket?.approved_on
                                  : context?.ticket?.rejected_at}
                              </Moment>
                            </div>
                          </FormGroup>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {!(
                location.pathname.includes("/new") ||
                (window.location.pathname.includes(
                  `/cleaning/${urlParams?.cleaningId}`
                ) &&
                  !window.location.pathname.includes(
                    `/cleaning/${urlParams?.cleaningId}/tickets`
                  ))
              ) &&
                (urlParams?.ticketId
                  ? context?.ticket?.status === "CREATED" ||
                    context?.ticket?.status === "VIEWED" ||
                    context?.ticket?.status === "REASSIGNED"
                  : false) && (
                  <div className="row mt-5">
                    <div className="col-md-6 col-12 pe-md-4 pt-4 ">
                      <div className=" d-flex d-block uploads align-items-center justify-content-center position-relative fileupload">
                        <div>
                          <img src={camera} alt="" width={24} />
                        </div>
                        <input
                          type={"file"}
                          name="myfile"
                          multiple
                          accept="image/png, image/gif, image/jpeg"
                          onChange={(e: any) => uploadImage(e.target.files)}
                        />
                        <h6 className=" mb-0 mx-3 fw-semibold ">
                          {t("new_tickets.UPLOAD_PHOTOS")}
                        </h6>
                      </div>
                    </div>
                    <div
                      className={
                        i18n.language === "en"
                          ? "pt-4 col-md-6 col-12 ps-md-5 formprofile"
                          : "col-md-6 col-12 pe-md-5 pt-4"
                      }
                    >
                      <div className=" d-flex d-block uploads align-items-center justify-content-center">
                        <div
                          className=" d-flex align-items-center "
                          onClick={() => setButtonPopup(true)}
                        >
                          <h6 className=" m-0">{t("new_tickets.VOICE")}</h6>
                          <div className="iconforbillboardform ">
                            <img src={mic} alt="" width={24} />
                          </div>
                        </div>
                        <div>|</div>
                        <div className=" d-flex align-items-center position-relative videoupload overflow-hidden ">
                          <h6 className=" mx-3 mb-0">
                            {t("new_tickets.VIDEO")}
                          </h6>
                          <input
                            type={"file"}
                            name="myfile"
                            multiple
                            accept=".mp4,.mpeg,.avi"
                            className="inputfilevideo"
                            onChange={(e: any) =>
                              getVideoUpload(e.target.files)
                            }
                          />
                          <div className="iconforbillboardform">
                            <img src={video} alt="" width={24} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {(formik?.values?.pictures?.length > 0 ||
                formik?.values?.videos?.length > 0 ||
                formik?.values?.audios?.length > 0) && (
                <>
                  <div
                    className={
                      i18n.language === "en" ? "pt-5" : " pt-5 pe-md-4"
                    }
                  >
                    <label htmlFor="" className="attachline">
                      {t("new_tickets.Attachments")}
                    </label>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-12 pe-md-4 ">
                      <div className=" d-flex py-5 flex-wrap gap-4">
                        {formik?.values?.pictures.map(
                          (img: any, index: any) => {
                            return (
                              <div className="attachment" key={index}>
                                <div className="attachment position-relative">
                                  <img
                                    src={img}
                                    alt=""
                                    className="embet"
                                    onClick={() => {
                                      setImageShow(true);
                                      setCurrentImage(img);
                                    }}
                                  />
                                  {(urlParams?.ticketId
                                    ? context?.ticket?.status === "CREATED" ||
                                      context?.ticket?.status === "VIEWED" ||
                                      context?.ticket?.status === "REASSIGNED"
                                    : true) && (
                                    <img
                                      src={x}
                                      alt=""
                                      width={15}
                                      className=" position-absolute x_close"
                                      // onClick={() => deleteImage(img, index)}
                                      onClick={() => {
                                        setDeleteAttachment(true);
                                        setCurrentAttachment({
                                          file: img,
                                          index: index,
                                        });
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        i18n.language === "en"
                          ? "pt-4 col-md-6 col-12 ps-md-5"
                          : " col-md-6 col-12 pe-md-5 "
                      }
                    >
                      <div className="">
                        {formik?.values?.audios?.map(
                          (audio: any, index: any) => {
                            return (
                              <div
                                className={
                                  i18n.language === "en"
                                    ? " sound position-relative my-5"
                                    : " soundArabic sound position-relative my-5"
                                }
                              >
                                <img
                                  src={x}
                                  alt=""
                                  width={15}
                                  className=" position-absolute x_close"
                                  onClick={() => {
                                    setDeleteAttachmentAudio(true);
                                    setCurrentAttachmentAudio({
                                      file: audio,
                                      index: index,
                                    });
                                  }}
                                />
                                {/* <Waveform audio={audio?.base64} /> */}
                                <audio src={audio} controls></audio>
                              </div>
                            );
                          }
                        )}
                        {formik?.values?.videos?.map(
                          (video: any, index: any) => {
                            return (
                              <div className="my-4 position-relative ">
                                {(urlParams?.ticketId
                                  ? context?.ticket?.status === "CREATED" ||
                                    context?.ticket?.status === "VIEWED" ||
                                    context?.ticket?.status === "REASSIGNED"
                                  : true) && (
                                  <img
                                    src={x}
                                    alt=""
                                    width={15}
                                    className=" position-absolute x_close"
                                    // onClick={() => deleteVideo(video, index)}
                                    onClick={() => {
                                      setDeleteAttachmentVideo(true);
                                      setCurrentAttachmentVideo({
                                        file: video,
                                        index: index,
                                      });
                                    }}
                                  />
                                )}
                                <VideoPlayer
                                  volume={currentAttachmentVideo?.volume}
                                  url={video}
                                  isPlaying={
                                    currentAttachmentVideo?.index === index
                                  }
                                  onPlay={() => {
                                    setCurrentAttachmentVideo({
                                      file: video,
                                      index: index,
                                    });
                                    setCurrentAttachmentSubmittedVideo({
                                      ...currentAttachmentSubmittedVideo,
                                      index: null,
                                    });
                                  }}
                                  onPause={() => {
                                    setCurrentAttachmentVideo({
                                      file: video,
                                      index: null,
                                    });
                                    setCurrentAttachmentSubmittedVideo({
                                      ...currentAttachmentSubmittedVideo,
                                      index: null,
                                    });
                                  }}
                                  onVolume={(value: any) => {
                                    setCurrentAttachmentVideo({
                                      file: video,
                                      index: index,
                                      volume: value,
                                    });
                                    setCurrentAttachmentSubmittedVideo({
                                      ...currentAttachmentSubmittedVideo,
                                      index: null,
                                    });
                                  }}
                                  // height="360px"
                                  // width="340px"
                                  width="100%"
                                  height="100%"
                                />
                                {/* <video width="100%" height="300" controls>
                              <source src={video} />
                              Your browser does not support HTML5 video.
                            </video> */}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {urlParams?.ticketId &&
                (context?.ticket?.submitted_pictures?.length > 0 ||
                  context?.ticket?.submitted_videos?.length > 0 ||
                  context?.ticket?.submitted_audios?.length > 0) && (
                  <>
                    <div
                      className={
                        i18n.language === "en" ? "pt-5" : " pt-5 pe-md-4"
                      }
                    >
                      <label htmlFor="" className="attachline">
                        {t("new_tickets.Submitted")}
                      </label>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-12 pe-md-4 ">
                        <div className=" d-flex py-5 flex-wrap gap-4">
                          {context?.ticket?.submitted_pictures.map(
                            (img: any, index: any) => {
                              return (
                                <div className="attachment" key={index}>
                                  <div className="attachment position-relative">
                                    <img
                                      src={img}
                                      alt=""
                                      className="embet"
                                      onClick={() => {
                                        setImageShow(true);
                                        setCurrentImage(img);
                                      }}
                                    />
                                    {(urlParams?.ticketId
                                      ? context?.ticket?.status === "CREATED" ||
                                        context?.ticket?.status === "VIEWED" ||
                                        context?.ticket?.status === "REASSIGNED"
                                      : true) && (
                                      <img
                                        src={x}
                                        alt=""
                                        width={15}
                                        className=" position-absolute x_close"
                                        // onClick={() => deleteImage(img, index)}
                                        onClick={() => {
                                          setDeleteAttachment(true);
                                          setCurrentAttachment({
                                            file: img,
                                            index: index,
                                          });
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                      <div
                        className={
                          i18n.language === "en"
                            ? "pt-4 col-md-6 col-12 ps-md-5"
                            : " col-md-6 col-12 pe-md-5 "
                        }
                      >
                        <div className="">
                          {context?.ticket?.submitted_audios?.map(
                            (audio: any, index: any) => {
                              return (
                                <div
                                  className={
                                    i18n.language === "en"
                                      ? " sound position-relative my-5"
                                      : " soundArabic sound position-relative my-5"
                                  }
                                >
                                  <img
                                    src={x}
                                    alt=""
                                    width={15}
                                    className=" position-absolute x_close"
                                    onClick={() => {
                                      setDeleteAttachmentAudio(true);
                                      setCurrentAttachmentAudio({
                                        file: audio,
                                        index: index,
                                      });
                                    }}
                                  />
                                  {/* <Waveform audio={audio?.base64} /> */}
                                  <audio src={audio} controls></audio>
                                </div>
                              );
                            }
                          )}
                          {context?.ticket?.submitted_videos?.map(
                            (video: any, index: any) => {
                              return (
                                <div className="my-4 position-relative ">
                                  {(urlParams?.ticketId
                                    ? context?.ticket?.status === "CREATED" ||
                                      context?.ticket?.status === "VIEWED" ||
                                      context?.ticket?.status === "REASSIGNED"
                                    : true) && (
                                    <img
                                      src={x}
                                      alt=""
                                      width={15}
                                      className=" position-absolute x_close"
                                      // onClick={() => deleteVideo(video, index)}
                                      onClick={() => {
                                        setDeleteAttachmentVideo(true);
                                        setCurrentAttachmentSubmittedVideo({
                                          file: video,
                                          index: index,
                                        });
                                      }}
                                    />
                                  )}
                                  <VideoPlayer
                                    volume={
                                      currentAttachmentSubmittedVideo?.volume
                                    }
                                    url={video}
                                    isPlaying={
                                      currentAttachmentSubmittedVideo?.index ===
                                      index
                                    }
                                    onPlay={() => {
                                      setCurrentAttachmentSubmittedVideo({
                                        file: video,
                                        index: index,
                                      });
                                      setCurrentAttachmentVideo({
                                        ...currentAttachmentVideo,
                                        index: null,
                                      });
                                    }}
                                    onPause={() => {
                                      setCurrentAttachmentSubmittedVideo({
                                        file: video,
                                        index: null,
                                      });
                                      setCurrentAttachmentVideo({
                                        ...currentAttachmentVideo,
                                        index: null,
                                      });
                                    }}
                                    onVolume={(value: any) => {
                                      setCurrentAttachmentSubmittedVideo({
                                        file: video,
                                        index: index,
                                        volume: value,
                                      });
                                      setCurrentAttachmentVideo({
                                        ...currentAttachmentVideo,
                                        index: null,
                                      });
                                    }}
                                    height="360px"
                                    width="340px"
                                  />
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              {(location.pathname.includes("/cleaning") ||
                (urlParams?.cleaningId && urlParams?.ticketId
                  ? context?.ticket?.status === "CREATED" ||
                    context?.ticket?.status === "VIEWED" ||
                    context?.ticket?.status === "REASSIGNED"
                  : false)) &&
                userContext?.roleAccess["TICKETS"].includes("UPDATE") && (
                  <>
                    <div
                      className={`${
                        isMobileOnly
                          ? "d-flex py-5 flex-column"
                          : "d-flex justify-content-end py-5"
                      }`}
                    >
                      {urlParams?.ticketId &&
                        context?.ticket?.created_by?.id ===
                          userContext?.user?.id && (
                          <CommonButton
                            text={t("chat.Start Conversation")}
                            variant="success"
                            isLoading={isSubmitting}
                            className="mx-3 text-white"
                            onClick={() => {
                              createGroupConversation();
                            }}
                          />
                        )}
                      <CommonButton
                        text={t("Reset_form")}
                        variant="outline-danger"
                        className="mx-3 bg-transparent text-danger"
                        isLoading={isSubmitting}
                        isDisabled={isSubmitting || !dirty}
                        onClick={() => {
                          setHeaderMessage(
                            `${t("successError.cancel the changes")}`
                          );
                          setTextMessage(`${t("successError.cancel it")}`);
                          setBodyMessage(`${t("successError.cancel it")}`);
                          setConformationPopUp(true);
                        }}
                      />

                      <CommonButton
                        text={
                          urlParams?.cleaningId
                            ? `${t("cleaning.Update_group")}`
                            : `${t("cleaning.Create_group")}`
                        }
                        variant="success"
                        className="mx-3 text-white"
                        isLoading={isSubmitting}
                        isDisabled={isSubmitting || !isValid || !dirty}
                        onClick={() => {
                          setSubmitting(true);
                          setConformationPopUp(!!urlParams.cleaningId);
                          setTextMessage(
                            urlParams.cleaningId && t("successError.update it")
                          );
                          setHeaderMessage(
                            urlParams.cleaningId &&
                              t("successError.Update the group")
                          );
                          if (!urlParams.cleaningId) {
                            handleUpdateData(formik.values);
                          }
                          setSubmitting(false);
                        }}
                      />
                    </div>
                  </>
                )}

              {urlParams?.ticketId &&
                userContext?.roleAccess["TICKETS"].includes("VERIFY") &&
                context?.ticket?.status === "SUBMITTED" && (
                  <div
                    className={`${
                      isMobileOnly
                        ? "d-flex justify-content-end py-5"
                        : "d-flex justify-content-end py-5"
                    }`}
                  >
                    {userContext?.roleAccess["TICKETS"].includes(
                      "REASSIGN"
                    ) && (
                      <CommonButton
                        text={t("Progressbar_status.REASSIGN")}
                        variant="outline-danger"
                        className="mx-3 bg-transparent text-danger"
                        isLoading={isSubmitting}
                        onClick={() => {
                          setSubmitting(true);
                          setConformationPopUp(true);
                          setTextMessage(t("successError.reassign it"));
                          setHeaderMessage(
                            t("successError.reassign the ticket")
                          );
                          setSubmitting(false);
                        }}
                      />
                    )}

                    <CommonButton
                      text={t("CreateBillBoardForm.verifyticket")}
                      variant="success"
                      isLoading={isSubmitting}
                      className=" text-white"
                      onClick={() => {
                        setSubmitting(true);
                        setConformationPopUp(true);
                        setTextMessage(t("successError.verify it"));
                        setHeaderMessage(t("successError.verify the ticket"));
                        setSubmitting(false);
                      }}
                    />
                  </div>
                )}

              {urlParams?.ticketId &&
                userContext?.roleAccess["TICKETS"].includes("APPROVE/REJECT") &&
                context?.ticket?.status === "VERIFIED" && (
                  <div className="d-flex justify-content-end py-5">
                    <CommonButton
                      text={t("CreateBillBoardForm.reject")}
                      variant="outline-danger"
                      className="mx-3 bg-transparent text-danger"
                      isLoading={isSubmitting}
                      onClick={() => {
                        setSubmitting(true);
                        setConformationPopUp(true);
                        setTextMessage(t("successError.reject it"));
                        setHeaderMessage(t("successError.reject the ticket"));
                        setSubmitting(false);
                      }}
                    />
                    <CommonButton
                      text={t("CreateBillBoardForm.approvedticket")}
                      variant="success"
                      isLoading={isSubmitting}
                      className=" text-white"
                      onClick={() => {
                        setSubmitting(true);
                        setConformationPopUp(true);
                        setTextMessage(t("successError.approve it"));
                        setHeaderMessage(t("successError.approve the ticket"));
                        setSubmitting(false);
                      }}
                    />

                    {userContext?.roleAccess["TICKETS"].includes(
                      "REASSIGN"
                    ) && (
                      <CommonButton
                        text={t("Progressbar_status.REASSIGN")}
                        variant="outline-danger"
                        className="mx-3 bg-transparent text-danger"
                        isLoading={isSubmitting}
                        onClick={() => {
                          setSubmitting(true);
                          setConformationPopUp(true);
                          setTextMessage(t("successError.reassign it"));
                          setHeaderMessage(
                            t("successError.reassign the ticket")
                          );
                          setSubmitting(false);
                        }}
                      />
                    )}
                  </div>
                )}
            </div>

            <Modal trigger={buttonPopup}>
              <AudioUpload
                setTrigger={setButtonPopup}
                userContext={userContext}
                uploadAudio={(data: any) => {
                  formik?.setFieldValue("audios", [
                    ...formik?.values?.audios,
                    data,
                  ]);
                }}
                setButtonPopup={setButtonPopup}
              />
            </Modal>
            <ToastPopup trigger={timedPopup} setTrigger={setTimedPopup}>
              <SuccessErrorToast
                bodyMessage={bodyMessage}
                isSuccess={isSuccess}
                okButton={() => setTimedPopup(false)}
                buttonName="Close"
              />
            </ToastPopup>
            <ImagePopUp
              imageShow={imageShow}
              image={currentImage}
              setImageShow={(data: any) => setImageShow(data)}
            />
            <ToastPopup trigger={loading} setTrigger={setLoading}>
              <SuccessErrorToast isLoading={true} />
            </ToastPopup>
            {/* <ToastPopupSecond trigger={showPopUp} setTrigger={setShowPopUp}>
              <DeleteBlockToast
                closePopUp={() => uploadVideo(true)}
                submit={() => uploadVideo(false)}
                textMessage={bodyMessage}
                cancelMessage={cancelMessage}
                headerMessage={headerMessage}
              />
            </ToastPopupSecond> */}
            <ToastPopupSecond
              trigger={
                deleteAttachment
                  ? deleteAttachment
                  : deleteAttachmentVideo
                  ? deleteAttachmentVideo
                  : deleteAttachmentAudio
                  ? deleteAttachmentAudio
                  : ""
              }
              setTrigger={
                deleteAttachment
                  ? setDeleteAttachment
                  : deleteAttachmentVideo
                  ? setDeleteAttachmentVideo
                  : deleteAttachmentAudio
                  ? setDeleteAttachmentAudio
                  : ""
              }
            >
              <DeleteBlockToast
                textMessage={`${t("successError.remove it")}`}
                headerMessage={
                  deleteAttachment
                    ? `${t("successError.remove this image")}`
                    : deleteAttachmentVideo
                    ? `${t("successError.remove this video")}`
                    : deleteAttachmentAudio &&
                      `${t("successError.remove this audio")}`
                }
                submit={() =>
                  deleteAttachment
                    ? deleteImage()
                    : deleteAttachmentVideo
                    ? deleteVideo()
                    : deleteAttachmentAudio
                    ? deleteAudio()
                    : ""
                }
                closePopUp={() =>
                  deleteAttachment
                    ? setDeleteAttachment(false)
                    : deleteAttachmentVideo
                    ? setDeleteAttachmentVideo(false)
                    : deleteAttachmentAudio
                    ? setDeleteAttachmentAudio(false)
                    : false
                }
              />
            </ToastPopupSecond>
            <ToastPopupSecond
              trigger={conformationPopUp}
              setTrigger={setConformationPopUp}
            >
              <DeleteBlockToast
                closePopUp={(data: any) => setConformationPopUp(data)}
                submit={() => {
                  setConformationPopUp(false);
                  setImageShow(false);
                  headerMessage === t("successError.verify the ticket")
                    ? approveTicket(true)
                    : headerMessage === t("successError.approve the ticket")
                    ? approveTicket(false)
                    : headerMessage === t("successError.reject the ticket")
                    ? rejectTicket()
                    : headerMessage === t("successError.reassign the ticket")
                    ? reAssignTicket()
                    : headerMessage === t("successError.cancel the changes")
                    ? cancelForm()
                    : handleUpdateData(formik.values);
                }}
                headerMessage={headerMessage}
                textMessage={textMessage}
              />
            </ToastPopupSecond>
          </Form>
        </FormikProvider>
      )}
    </div>
  );
};
export default CreateNewGroupForm;
