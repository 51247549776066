import { useNavigate } from "react-router-dom";
import { useChatContext } from "../../../context/ChatProvider";
import SearchField from "../components/SearchField/SearchField";
import InboxContact from "../components/InboxContact/InboxContact";
import "./Sidebar.scss";
import { useEffect, useRef, useState } from "react";
import { isMobile, isTablet } from "react-device-detect";
import ForwardPopup from "../forwardPopup/ForwardPopup";
import ChatLoader from "../../ChatLoadingPage/ChatLoader";
import CallHistory from "../CallHistory/CallHistory";
import Nodata from "../components/Nodata/Nodata";
import { BiSolidMessageSquareAdd, BiSolidPhoneCall } from "react-icons/bi";
import { IoArrowBackSharp } from "react-icons/io5";
import { routes } from "../../../../../constants/routes";

interface ResponsiveProps {
  isResponsive?: boolean;
  setisResponsive?: Function;
}

export default function Sidebar({
  isResponsive,
  setisResponsive,
}: ResponsiveProps) {
  const navigate = useNavigate();
  const chatContext = useChatContext();
  const [show, setShow] = useState(false);
  const [conversationList, setConversationList] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [callHistory, setCallHistory] = useState(false);
  const [limit, setLimit] = useState<number>(20);
  const [totalCount, setTotalCount] = useState<number>(10);
  const handleChangeChat = (chat: any) => {
    chatContext.onChangeChat(chat);
    navigate("/chat/" + chat.id);
  };

  const handleSearch = (query: string) => {
    conversationList?.filter((conversation) =>
      conversation?.name.toLowerCase().includes(query.toLowerCase())
    );
  };

  const listAllConversation = async () => {
    const { success, conversation, error, meta } =
      await chatContext.listConversation({
        search: searchQuery,
        limit: limit,
        group: chatContext?.isGroup,
      });
    success &&
      setConversationList((prevValue: any) => ({
        ...prevValue,
        success: success,
        conversation: conversation,
        error: error,
        meta: meta,
      }));
    setTotalCount(meta?.total_count);
  };

  useEffect(() => {
    listAllConversation();
  }, [searchQuery, limit, chatContext?.isGroup]);

  const containerRef = useRef(null);

  const handleScroll = () => {
    if (!containerRef.current) return;
    const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      loadMoreData();
    }
  };
  // useEffect(() => {
  //   if (chatContext?.callRequested) {
  //     const timer = setTimeout(() => {
  //       chatContext?.setCallRequested(false);
  //       chatContext?.handleDisconnect(
  //         "DISCONNECT",
  //         chatContext?.userInfo?.user?.id,
  //         "MISCALL",
  //         chatContext?.userInfo?.conversationId
  //       );
  //     }, 10000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [chatContext?.callRequested]);

  const loadMoreData = () => {
    if (limit < totalCount) {
      setLimit(limit + 10);
    }
  };

  return (
    <div
      className={`sidebar-container ${
        isTablet || isMobile ? (isResponsive ? "d-none" : "") : ""
      }`}
    >
      {chatContext?.isLoading && <ChatLoader />}
      {/* {chatContext?.callRequested && <IncomingCall />}
      {chatContext.isCallAccepted && <AudioAndVideo />} */}

      <div className="Header p-3">
        <h5 className=" fw-semibold mb-0 d-flex align-items-center">
          {" "}
          <span
            className={`me-2 cursor-pointer`}
            onClick={() => navigate(routes.dashboard)}
          >
            <IoArrowBackSharp
              fontSize={22}
              color="var(--secondary)"
              title="Back to main page"
            />
          </span>
          Chats
        </h5>
        <div className=" d-flex align-items-center">
          <span onClick={() => setShow(true)} className=" cursor-pointer px-2">
            <BiSolidMessageSquareAdd
              color="var(--secondary)"
              size={24}
              title="New chat"
            />
          </span>
          {show && (
            <ForwardPopup
              show={show}
              setShow={setShow}
              forwardModalshow={false}
            />
          )}
          {/* <div
            className=" position-relative call-history-wrapper d-flex justify-content-center align-items-center"
            title="Call History"
            onClick={() => setCallHistory(true)}
          >
            <span className=" call-history-icon">
              <HiSwitchHorizontal size={17} />
            </span>
            <IoCall />  
          </div> */}
          {/* -----------------commented for deploying purpose---------------------------- */}
          {/* <span
            onClick={() => setCallHistory(true)}
            className="me-2 ms-1 cursor-pointer"
          >
            <BiSolidPhoneCall
              size={24}
              color="var(--secondary)"
              title="Call History"
            />
          </span> */}
          {callHistory && (
            <CallHistory
              callHistory={callHistory}
              setCallHistory={setCallHistory}
            />
          )}

          {/* <OptionsDropdown iconSize="23">
            <>
              <Dropdown.Item>Settings</Dropdown.Item>
              <Dropdown.Item>Logout</Dropdown.Item>
            </>
          </OptionsDropdown> */}
        </div>
      </div>
      <SearchField
        placeholder="Search"
        onSearch={handleSearch}
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
      />
      <div className="p-3 d-flex  align-items-center gap-2 border-top">
        <div
          className={`chat-list-sections  ${
            !chatContext?.isGroup && "active"
          } `}
          onClick={() => chatContext?.setIsGroup(false)}
        >
          All
        </div>
        <div
          className={`chat-list-sections  ${chatContext?.isGroup && "active"} `}
          onClick={() => chatContext?.setIsGroup(true)}
        >
          Groups
        </div>
      </div>
      <div
        className="contact-container"
        ref={containerRef}
        onScroll={handleScroll}
      >
        {chatContext?.conversationList?.length > 0 ? (
          chatContext?.conversationList?.map((inbox: any) => (
            <InboxContact
              key={inbox._id}
              inbox={inbox}
              isActive={inbox.id === chatContext.activeChat?.id}
              onChangeChat={handleChangeChat}
              setisResponsive={setisResponsive}
              isResponsive={isResponsive}
            />
          ))
        ) : (
          <Nodata noDataText="No Chats, messages found" />
        )}
      </div>
    </div>
  );
}
