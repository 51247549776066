import Modal from "react-bootstrap/Modal";
import { RxCross2 } from "react-icons/rx";
import { useChatContext } from "../../../context/ChatProvider";
import { useUserContext } from "../../../context/UserProvider";
import Avatar from "react-avatar";
import { IoCall } from "react-icons/io5";
import { FaVideo } from "react-icons/fa";
import { GoArrowUpRight, GoArrowDownLeft } from "react-icons/go";
import { useEffect } from "react";
import AudioAndVideo from "../AudioAndVideo/AudioAndVideo";

import "./CallHistory.scss";
import Nodata from "../components/Nodata/Nodata";
import {
  calculateDuration,
  formatTimestamp,
} from "../../../../../utils/axiosInstance";

interface CallHistoryProps {
  setCallHistory: Function;
  callHistory: boolean;
}

function CallHistory({ setCallHistory, callHistory }: CallHistoryProps) {
  const chatContext = useChatContext();
  const userContext = useUserContext();

  const handleClose = () => {
    setCallHistory(false);
  };

  const handleAudioVideoCall = (data: any, mode: string) => {
    const opponentId =
      data?.from?.id !== userContext?.user?.id ? data?.from?.id : data?.to?.id;
    chatContext.handleIncommingCall(mode, data?.conversation_id, opponentId);
    setCallHistory(false);
  };

  const listCallHistory = async () => {
    await chatContext.listCallHistory({});
  };
  {
    (chatContext?.mode === "video" || chatContext?.mode === "audio") && (
      <AudioAndVideo />
    );
  }
  useEffect(() => {
    listCallHistory();
  }, []);
  return (
    <>
      {chatContext?.mode === "video" || chatContext?.mode === "audio" ? (
        <AudioAndVideo />
      ) : (
        <Modal show={callHistory}>
          <Modal.Body>
            <div className=" d-flex justify-content-between align-items-center pb-3">
              <h6 className=" fw-semibold mb-0">Recent Calls</h6>
              <div className="cursor-pointer">
                <RxCross2
                  fontSize={24}
                  color="var(--secondary)"
                  onClick={handleClose}
                />
              </div>
            </div>
            <div className=" mt-3 people-list">
              {chatContext?.callLogs?.length > 0 ? (
                chatContext?.callLogs?.map((item: any) => {
                  console.log(
                    userContext?.user?.id !== item?.from.id
                      ? item.from.name
                      : item?.to.name
                  );
                  return (
                    <div className=" d-flex align-items-center justify-content-between mb-4 m-inline-end">
                      <div className="d-flex gap-3 align-items-center">
                        <div className="call-history-list">
                          {(
                            item?.from?.id !== userContext?.user?.id
                              ? item?.from?.avatar
                              : item?.to?.avatar
                          ) ? (
                            <img
                              src={
                                item?.from?.id !== userContext?.user?.id
                                  ? item?.from?.avatar
                                  : item?.to?.avatar
                              }
                              alt={
                                item?.from?.id !== userContext?.user?.id
                                  ? item?.from?.name
                                  : item?.to?.name
                              }
                            />
                          ) : (
                            <Avatar
                              name={
                                item?.from?.id !== userContext?.user?.id
                                  ? item?.from?.name
                                  : item?.to?.name
                              }
                              size="45"
                              maxInitials={2}
                              round={true}
                            />
                          )}
                        </div>
                        <div>
                          <h6
                            className={` mb-0 fw-medium text-capitalize ${
                              item?.from?.id !== userContext?.user?.id &&
                              item?.status === "PENDING"
                                ? "text-danger"
                                : "text-dark"
                            }`}
                          >
                            {item?.from?.id !== userContext?.user?.id
                              ? item?.from?.name
                              : item?.to?.name}
                          </h6>
                          <div className=" d-flex align-items-center gap-2">
                            <div>
                              {item?.from?.id === userContext?.user?.id ? (
                                <GoArrowUpRight
                                  size={20}
                                  color={"var(--success)"}
                                />
                              ) : (
                                <GoArrowDownLeft
                                  size={20}
                                  color={
                                    item?.status === "PENDING"
                                      ? "var(--danger)"
                                      : "var(--success)"
                                  }
                                />
                              )}
                            </div>
                            <h6 className=" mb-0 fw-medium fs-13">
                              {formatTimestamp(item?.createdAt)},{" "}
                              <span className=" fs-13">
                                {" "}
                                {item?.from?.id !== userContext?.user?.id &&
                                item?.status === "PENDING"
                                  ? "Missed call"
                                  : item?.status === "COMPLETED"
                                  ? calculateDuration(
                                      item?.start_time,
                                      item?.end_time
                                    )
                                  : "No answer"}
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div>
                        {item.mode === "AUDIO" ? (
                          <div
                            className=" cursor-pointer"
                            onClick={() => handleAudioVideoCall(item, "audio")}
                          >
                            <IoCall
                              fontSize={22}
                              color="var(--secondary)"
                              title="Audio Call"
                            />
                          </div>
                        ) : (
                          <div
                            className=" cursor-pointer"
                            onClick={() => handleAudioVideoCall(item, "video")}
                          >
                            <FaVideo
                              fontSize={22}
                              color="var(--secondary)"
                              title="Video Call"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <Nodata noDataText={"No Recent Calls Found"} />
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default CallHistory;
