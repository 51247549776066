import { LIVE_URL, post, put } from "../axiosInstance";


interface CreateRoom {
    success: boolean;
    token: string;
}

const connectRoom = async (
    params: {
        roomName: string,
        participantName: string
    }
): Promise<CreateRoom | undefined> => {
    try {
        const response = await post(`${LIVE_URL}livekit/tokenGenerate`, null, params);
        return response?.data as CreateRoom;
    } catch (e: any) {
        console.error("Error while generating token", e);
    }
};

const sendMessage = async (
    params: {
        roomName: string,
        localParticipant: string,
        message: any,
        dId: any,
        topic: string,
        userName: string,
    }
): Promise<any> => {
    try {
        const response = await post(`${LIVE_URL}livekit/sendMessage`, null, params);
        return response as any;
    } catch (e: any) {
        console.error("Error sending message to livekit", e);
    }
};

const updateMeta = async (roomName: string, participantName: string, newMetadata: any): Promise<any> => {
    try {
        const response = await put(`${LIVE_URL}livekit/updateMeta`, null, { roomName, participantName, newMetadata });
        return response as any;
    } catch (e: any) {
        console.error("Error sending message to livekit", e);
    }
}

// const publishData = async (params: { topic: string, message: any, dId: any, roomName: string }): Promise<any> => {
//     try {
//         const room = new RoomServiceClient(
//             LIVEKIT_WEBSOCKET,
//             LIVEKIT_API_KEY,
//             LIVEKIT_SECRET_KEY
//         );
//         const encoder = new TextEncoder();
//         const data = encoder.encode(
//             `${undefined}$-${params.dId}$-${params.topic}$-${undefined}$-${JSON.stringify(
//                 params.message
//             )}`
//         );
//         await room.sendData(params.roomName, data, 0, {
//             destinationIdentities: params.dId,
//             topic: params.topic,
//         });
//         return true
//     } catch (error) {
//         console.error("Error while publishing data")
//     }
// }


const LiveKitService = {
    connectRoom,
    sendMessage,
    updateMeta,
    // publishData
};

export default LiveKitService;