import { LiveKitRoom } from "@livekit/components-react";
import { useCallback, useEffect, useState } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { Route, Routes } from "react-router-dom";
import { LIVEKIT_WEBSOCKET } from "../../../utils/axiosInstance";
import LiveKitService from "../../../utils/services/livekit.service";
import ChatProvider from "../context/ChatProvider";
import { useUserContext } from "../context/UserProvider";
import ChatPage from "../pages/chat/ChatPage/ChatPage";
import UnSelectedChatPage from "../pages/chat/UnSelectedChatPage/UnSelectedChatPage";

export default function AppRoutes() {
  // const [token, setToken] = useState<string | undefined>();
  // const userContext = useUserContext();
  // // console.log(userContext, "userContext");
  // const connectWithPublicRoom = useCallback(async () => {
  //   const userId = userContext?.user?.id;
  //   if (userId) {
  //     const res = await LiveKitService.connectRoom({
  //       roomName: "alarabia",
  //       participantName: userId,
  //     });
  //     if (res?.token) {
  //       setToken(res?.token);
  //       // await axios.post("http://localhost:3002/livekit/updateMeta", {
  //       //   roomName: "alarabia",
  //       //   participantIdentity: userId,
  //       //   metaData: JSON.stringify(userContext?.user),
  //       // });
  //     }
  //   }
  // }, [userContext?.user?.id]);
  // useEffect(() => {
  //   if (userContext?.user?.id) {
  //     connectWithPublicRoom();
  //   }
  // }, [connectWithPublicRoom, userContext?.user?.id]);

  return (
    <>
      {/* {token && (
        <LiveKitRoom
          serverUrl={LIVEKIT_WEBSOCKET}
          token={token}
          connect={true}
          video={false}
          audio={false}
        > */}
      <ChatProvider>
        <Routes>
          <Route path="/chat/:id" element={<ChatPage />} />
          <Route
            path="/chat"
            element={
              <div>
                {isTablet || isMobile ? <ChatPage /> : <UnSelectedChatPage />}
              </div>
            }
          />
        </Routes>
      </ChatProvider>
      {/* </LiveKitRoom> */}
      {/* )} */}
    </>
  );
}
