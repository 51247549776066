import React, { useCallback, useEffect, useRef, useState } from "react";

import { useRoomContext } from "@livekit/components-react";
import { RoomEvent } from "livekit-client";
import { useUserContext } from "./UserProvider";
import notification from "./assets/sound/whatsapp_notification.mp3";
import callTune from "./assets/sound/whatsapp_ringtone.mp3";
import ChatService from "../../../utils/services/chat.service";
import LiveKitService from "../../../utils/services/livekit.service";
import FileService, {
  FileInterface,
} from "../../../utils/services/file.service";
import { useAuthentication } from "../../../context/AuthenticationProvider";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
type GroupMember = {
  id: string | undefined;
  name: string | undefined;
};

export type ChatContextProp = {
  setIsLoading: Function;
  isLoading: boolean;
  user: any[];
  inbox: any[];
  activeChat?: any;
  onChangeChat: (chat: any) => void;
  listConversation: Function;
  getMessagesByConversationID: Function;
  messages: any;
  meta: any;
  sendMessage: Function;
  conversationList: any;
  fileUpload: any;
  getIndividualMessageID: Function;
  repliedMessage: any;
  setRepliedMessage: Function;
  messageForwards: Function;
  forwardMessageCount: [];
  setForwardMessageCount: Function;
  // forwardMessageUserID: [];
  // setForwardMessageUserID: Function;
  setMessages: Function;
  newConversation: Function;
  readConversation: Function;
  setAttachment: Function;
  attachment: any;
  setIsPreview: Function;
  isPreview: any;
  setMode: Function;
  mode: string;
  handleIncommingCall: Function;
  userInfo: any;
  callRequested: boolean;
  setCallRequested: Function;
  handleDisconnect: Function;
  audioCallshow: boolean;
  setAudioCallshow: Function;
  videoCallshow: boolean;
  setVideoCallshow: Function;
  handleAcceptCall: any;
  isCallAccepted: boolean;
  setCallAccepted: Function;
  messageEdit: Function;
  messageDelete: Function;
  createGroup: Function;
  addMemberToGroup: Function;
  updateGroupName: Function;
  removeUserFromGroup: Function;
  groupMembers: GroupMember[];
  setGroupMembers: Function;
  deleteMessage: any;
  setDeleteMessage: Function;
  editGroupName: any;
  setEditGroupName: Function;
  listCallHistory: Function;
  callLogs: any;
  callerId: string;
  selectedMsgIds: [];
  setSelectedMsgIds: Function;
  messageLimit: number;
  setMessageLimit: (limit: number) => void;
  audio: any;
  ringTone: any;
  setExitGroup: Function;
  exitGroup: any;
  isGroup: boolean;
  setIsGroup: Function;
  callWaiting: boolean;
  isDisconnecting: boolean;
  callInitializing: boolean;
  forwardList: Function;
  forwardListWithGroup: any;
  setForwardListWithGroup: Function;
  newMessage: any;
  // forwardMessageGrpId: any;
  // setForwardMessageGrpId: any;
};

const initialValue: ChatContextProp = {
  user: [],
  inbox: [],
  onChangeChat() {
    throw new Error();
  },
  listConversation: () => {},
  getMessagesByConversationID: () => {},
  messages: undefined,
  meta: undefined,
  conversationList: undefined,
  sendMessage: () => {},
  fileUpload: () => {},
  getIndividualMessageID: () => {},
  repliedMessage: undefined,
  setRepliedMessage: () => {},
  messageForwards: () => {},
  forwardMessageCount: [],
  setForwardMessageCount: () => {},
  // forwardMessageUserID: [],
  // setForwardMessageUserID: () => {},
  // forwardMessageGrpId: [],
  // setForwardMessageGrpId: () => {},
  setMessages: () => {},
  newConversation: () => {},
  setIsLoading: () => {},
  isLoading: false,
  readConversation: () => {},
  setAttachment: () => {},
  attachment: [],
  setIsPreview: () => {},
  isPreview: false,
  setMode: () => {},
  mode: "",
  handleIncommingCall: () => {},
  userInfo: undefined,
  callRequested: false,
  setCallRequested: () => {},
  handleDisconnect: () => {},
  audioCallshow: false,
  setAudioCallshow: () => {},
  videoCallshow: false,
  setVideoCallshow: () => {},
  handleAcceptCall: () => {},
  isCallAccepted: false,
  setCallAccepted: () => {},
  messageEdit: () => {},
  messageDelete: () => {},
  createGroup: () => {},
  addMemberToGroup: () => {},
  updateGroupName: () => {},
  removeUserFromGroup: () => {},
  groupMembers: [],
  setGroupMembers: () => {},
  deleteMessage: false,
  setDeleteMessage: () => {},
  editGroupName: false,
  setEditGroupName: () => {},
  listCallHistory: () => {},
  callLogs: undefined,
  callerId: "",
  selectedMsgIds: [],
  setSelectedMsgIds: () => {},
  messageLimit: 50,
  setMessageLimit: () => {},
  audio: [],
  ringTone: [],
  setExitGroup: () => {},
  exitGroup: false,
  isGroup: false,
  setIsGroup: () => {},
  callWaiting: false,
  isDisconnecting: false,
  callInitializing: false,
  forwardList: () => {},
  forwardListWithGroup: undefined,
  setForwardListWithGroup: () => {},
  newMessage: undefined,
};

export const ChatContext = React.createContext<ChatContextProp>(initialValue);
let count = 0;

export default function ChatProvider(props: { children: any }) {
  const { children } = props;
  const room = useRoomContext();
  const useContext = useAuthentication();
  const [isLoading, setIsLoading] = useState(false);
  const [user] = useState<any[]>(initialValue.user);
  const [inbox] = useState<any[]>(initialValue.inbox);
  const [activeChat, setActiveChat] = useState<any>();
  const [messages, setMessages] = useState<any>();
  const [conversationList, setConversationList] = useState<any>();
  const [callLogs, setCallLogs] = useState<any>();
  const [repliedMessage, setRepliedMessage] = useState<unknown>({});
  const [mode, setMode] = useState<string>("");
  const [userInfo, setUserInfo] = useState<any>();
  const [callRequested, setCallRequested] = useState<boolean>(false);
  const [audioCallshow, setAudioCallshow] = useState(false);
  const [videoCallshow, setVideoCallshow] = useState(false);
  const [isCallAccepted, setCallAccepted] = useState(false);
  const [callerId, setCallerId] = useState<string>("");
  const [isGroup, setIsGroup] = useState<boolean>(false);
  const [isCall, setIsCall] = useState<boolean>(false);
  const [callWaiting, setCallWaiting] = useState<boolean>(false);
  const [isDisconnecting, setDisconnecting] = useState<boolean>(false);
  const [newMessage, setNewMessage] = useState<any>();
  const [forwardMessageCount, setForwardMessageCount]: [[], Function] =
    useState([]);
  const [groupMembers, setGroupMembers] = useState<GroupMember[]>([]);
  const [messageLimit, setMessageLimit] = useState<number>(50);
  const [meta, setMeta] = useState<any>();
  const [attachment, setAttachment] = useState([]);
  const [isPreview, setIsPreview] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(false);
  const [editGroupName, setEditGroupName] = useState(false);
  const [selectedMsgIds, setSelectedMsgIds] = useState<any>([]);
  const [exitGroup, setExitGroup] = useState(false);
  const [callInitializing, setCallInitializing] = useState<boolean>(false);
  const handleChangeChat = (chat: any) => {
    setActiveChat("");
    setActiveChat(chat);
    readConversation(chat.id);
  };
  const [forwardListWithGroup, setForwardListWithGroup] = useState<any>();

  const [audio] = useState(new Audio(notification));
  const [ringTone] = useState(new Audio(callTune));

  const getIndividualMessageID = useCallback(async (id: any) => {
    const response = await ChatService.getIndividualMessage(id);
    const { success, message, error } = response;
    return { success, message, error };
  }, []);

  const handleAcceptCall = async () => {
    ringTone.pause();
    setCallInitializing(true);
    if (mode === "video") {
      setVideoCallshow(true);
      setAudioCallshow(true);
    } else {
      setAudioCallshow(true);
    }
    setCallRequested(false);
    setCallAccepted(true);
    setIsCall(true);
    const response = await ChatService.updateSession(
      userInfo?.session?.sessionInfo?.id,
      {
        status: "RECEIVED",
      }
    );
    if (response?.data?.success) {
      setCallInitializing(false);
    }
    //   await LiveKitService.sendMessage({
    //     roomName: "alarabia",
    //     localParticipant: useContext?.user?.id,
    //     message: {
    //       user: useContext?.user,
    //       disconnectType: "CONNECTED",
    //     },
    //     dId: [userInfo?.user?.id],
    //     topic: "CONNECTED",
    //     userName: useContext?.user?.name,
    //   });
    // }
  };
  const listConversation = useCallback(async (params: any) => {
    setIsLoading(true);
    const response = await ChatService.listConversions(params);
    const { success, conversation, error, meta } = response;
    const sortedData = await Promise.all(
      conversation.sort((a: any, b: any) => {
        const dateA = a.updatedAt ? new Date(a.updatedAt).getTime() : 0;
        const dateB = b.updatedAt ? new Date(b.updatedAt).getTime() : 0;
        return dateB - dateA;
      })
    );
    setConversationList(sortedData);
    setIsLoading(false);
    return { success, conversation, error, meta };
  }, []);

  const listCallHistory = useCallback(async (params: any) => {
    setIsLoading(true);
    const response = await ChatService.getCallLogs(params);
    setCallLogs(response.sessions);
    setIsLoading(false);
    return response;
  }, []);

  const newConversation = useCallback(async (id: any) => {
    setIsLoading(true);
    const response = await ChatService.startConversation(id);
    setActiveChat(response?.data?.conversation);
    setIsLoading(false);
    return response;
  }, []);

  const readConversation = useCallback(async (id: string) => {
    const response = await ChatService.readConversation(id);
    if (response?.data?.success)
      setConversationList((prevConversation: any) => {
        if (!Array.isArray(prevConversation)) {
          console.error("Messages is not an array:", prevConversation);
          return [];
        }
        const updateData = [...prevConversation];
        const index = updateData.findIndex((item: any) => item.id === id);
        if (index > -1) {
          updateData[index] = response.data.conversation;
        } else {
          updateData.push(response.data.conversation);
        }
        return updateData;
      });
    return response;
  }, []);

  const updateLiveInboxChat = async (conversation: any) => {
    if (activeChat?.id === conversation.id) {
      setConversationList((prevConversation: any) => {
        if (!Array.isArray(prevConversation)) {
          console.error("Messages is not an array:", prevConversation);
          return [];
        }
        const index = prevConversation.findIndex(
          (item: any) => item.id === conversation.id
        );
        const updatedData = [conversation];
        if (index >= 0) {
          updatedData.push(...prevConversation.filter((_, i) => i !== index));
        } else {
          updatedData.push(...prevConversation);
        }
        return updatedData;
      });
    } else {
      setConversationList((prevConversation: any) => {
        if (!Array.isArray(prevConversation)) {
          console.error("Messages is not an array:", prevConversation);
          return [];
        }
        const existingConversationIndex = prevConversation.findIndex(
          (item: any) => item.id === conversation.id
        );
        let updatedData;
        if (existingConversationIndex >= 0) {
          updatedData = prevConversation.map((item: any, index: number) => {
            if (index === existingConversationIndex) {
              return {
                ...item,
                unread: (item.unread || 0) + 1,
                last_message: conversation?.last_message,
                last_message_sent_at: conversation?.last_message_sent_at,
              };
            }
            return item;
          });
        } else {
          updatedData = [...prevConversation, { ...conversation, unread: 1 }];
        }
        return updatedData;
      });
    }
  };
  const editMessageUpdate = async (message: any) => {
    if (activeChat?.id === message?.conversation_id) {
      setMessages((prevMessages: any) => {
        if (!Array.isArray(prevMessages)) {
          console.error("Messages is not an array:", prevMessages);
          return [];
        }
        const updatedMessages = [...prevMessages];
        const index = updatedMessages.findIndex(
          (item: any) => item.reference_id === message.reference_id
        );
        if (index > -1) {
          updatedMessages[index] = message;
        } else {
          updatedMessages.push(message);
        }
        return updatedMessages;
      });
    }
  };
  const handleDataReceived = useCallback(
    async (payload: Uint8Array) => {
      const decoder = new TextDecoder();
      const data = decoder.decode(payload);
      const [
        localParticipant,
        participantIdentity,
        action,
        userName,
        message,
      ]: any = data.split("$-");
      try {
        const parsedData = JSON.parse(message);
        switch (action) {
          case "DISCONNECT":
            ringTone.pause();
            updateChatMessage(parsedData?.session);
            updateConversation(parsedData?.session?.conversation_id);
            setMode("");
            setVideoCallshow(false);
            setAudioCallshow(false);
            setCallAccepted(false);
            setCallRequested(false);
            setIsCall(false);
            setCallWaiting(false);
            break;
          case "CHAT":
          case "MESSAGE_UPDATE":
            if (parsedData?.messageObj.from?.id !== useContext?.user?.id) {
              audio.play();
              Notify({
                message: `You got new Message from ${userName}`,
              });
            }
            if (action === "MESSAGE_UPDATE") {
              editMessageUpdate(parsedData?.messageObj);
              updateLiveInboxChat(parsedData?.conversation);
            } else {
              updateChatMessage(parsedData?.messageObj);
              if (parsedData?.messageObj?.to && !isGroup) {
                updateLiveInboxChat(parsedData?.conversation);
              }
              if (parsedData?.messageObj?.to === null) {
                updateLiveInboxChat(parsedData?.conversation);
              }
              if (activeChat?.id === parsedData?.conversation?._id) {
                setNewMessage(parsedData?.messageObj);
              }
            }
            break;
          case "audio":
          case "video":
            if (!isCall) {
              updateChatMessage(parsedData?.session);
              updateConversation(parsedData?.session?.conversation_id);
              setMode(action);
              setUserInfo(parsedData);
              setCallRequested(true);
            } else {
              await LiveKitService.sendMessage({
                roomName: "alarabia",
                localParticipant: useContext?.user?.id,
                message: {
                  user: useContext?.user,
                  disconnectType: "CALL_WAITING",
                },
                dId: [localParticipant],
                topic: "CALL_WAITING",
                userName: useContext?.user?.name,
              });
            }
            break;
          case "GROUP":
            updateConversation(parsedData?.conversation?.id);
            break;
          case "GROUP_UPDATE":
            if (parsedData.id === activeChat.id) {
              setActiveChat(parsedData);
            }
            updateConversation(parsedData?.id);
            break;
          case "CALL_WAITING":
            setCallWaiting(true);
            break;
          case "FORWARD_MESSAGE":
            listConversation({});
            break;
          default:
            console.warn(`Unhandled action type: ${action}`);
        }
      } catch (error) {
        console.error("Error parsing message:", error);
      }
    },
    [activeChat, isCall, isGroup]
  );

  useEffect(() => {
    room.on(RoomEvent.DataReceived, handleDataReceived);
    return () => {
      room.off(RoomEvent.DataReceived, handleDataReceived);
    };
  }, [handleDataReceived, room, activeChat]);

  const getMessagesByConversationID = useCallback(
    async (id: string, params: any) => {
      const response = await ChatService.listIndividualConversions(id, params);
      const { success, messages, error, meta } = response;
      setMessages(messages.reverse());
      setMeta(meta);
      return { success, messages, error, meta };
    },
    []
  );
  const handleDisconnect = useCallback(
    async (
      topic: string,
      dI: string,
      message: string,
      conversation_id: string
    ) => {
      let response;
      ringTone.pause();
      setDisconnecting(true);
      const sessionInfo = await ChatService.getSessionById(conversation_id);
      if (sessionInfo?.id) {
        if (sessionInfo.status === "REQUESTED") {
          response = await ChatService.updateSession(sessionInfo?.id, {
            status: "PENDING",
          });
        } else if (sessionInfo.status === "RECEIVED") {
          response = await ChatService.updateSession(sessionInfo?.id, {
            status: "COMPLETED",
          });
        }
      }
      if (response?.data?.success) {
        if (useContext?.user?.id) {
          await LiveKitService.sendMessage({
            roomName: "alarabia",
            localParticipant: useContext?.user?.id,
            message: {
              user: useContext?.user,
              disconnectType: message,
              session: response?.data?.message,
            },
            dId: [dI, useContext?.user?.id],
            topic: topic,
            userName: useContext?.user?.name,
          });
        }
      }
      setCallAccepted(false);
      setCallRequested(false);
      setIsCall(false);
      setCallWaiting(false);
      setDisconnecting(false);
      return response?.data;
    },
    [userInfo]
  );

  const handleIncommingCall = useCallback(
    async (topic: string, conversationId: string, dI: string) => {
      setCallInitializing(true);
      setCallerId(dI);
      if (topic === "audio") {
        setAudioCallshow(true);
      } else if (topic === "video") {
        setAudioCallshow(true);
        setVideoCallshow(true);
      }
      setIsCall(true);
      setMode(topic as "video" | "audio");
      const createSession = await ChatService.createSession({
        recevier_id: dI,
        mode: topic.toUpperCase(),
        conversation_id: conversationId,
      });
      if (createSession?.data?.success) {
        updateChatMessage(createSession?.data?.message);
        try {
          if (useContext?.user?.id) {
            await LiveKitService.sendMessage({
              roomName: "alarabia",
              localParticipant: useContext?.user?.id,
              message: {
                conversationId,
                user: useContext?.user,
                dI: dI,
                session: createSession?.data?.message,
              },
              dId: [dI],
              topic,
              userName: useContext.user.name,
            });
          }
          setCallInitializing(false);
        } catch (error) {
          console.error("Error handling incoming call:", error);
        }
      }
    },
    [useContext]
  );

  const updateChatMessage = (message: any) => {
    if (activeChat?.id === message?.conversation_id) {
      setMessages((prevMessages: any) => {
        if (!Array.isArray(prevMessages)) {
          console.error("Messages is not an array:", prevMessages);
          return [];
        }
        const updatedMessages = [...prevMessages];
        const index = updatedMessages.findIndex(
          (item: any) => item.id === message.id
        );
        if (index > -1) {
          updatedMessages[index] = message;
        } else {
          updatedMessages.push(message);
        }
        return updatedMessages;
      });
    }
  };
  const updateConversation = async (conversationId: string) => {
    if (activeChat?.id === conversationId) {
      readConversation(conversationId);
    }
    const response = await ChatService.getIndividualConversation(
      conversationId
    );
    if (response.success) {
      setConversationList((prevConversation: any) => {
        if (!Array.isArray(prevConversation)) {
          console.error("Messages is not an array:", prevConversation);
          return [];
        }
        const index = prevConversation.findIndex(
          (item: any) => item.id === conversationId
        );
        const updatedData = [response.data];
        if (index >= 0) {
          updatedData.push(...prevConversation.filter((_, i) => i !== index));
        } else {
          updatedData.push(...prevConversation);
        }

        return updatedData;
      });
    }
  };

  const sendMessage = useCallback(
    async (
      id: string,
      params: any,
      localParticipant: string,
      userName: string
    ) => {
      const data = { ...params };
      data.reply_message_id = repliedMessage;
      const opponentId = activeChat?.participants.find(
        (item: any) => item._id !== useContext?.user?.id
      )._id;
      data.from = useContext?.user;
      data.to = activeChat?.group_id?.id
        ? null
        : activeChat?.participants.find(
            (item: any) => item.id !== useContext?.user?.id
          );
      data.group_id = activeChat?.group_id?.id
        ? activeChat?.group_id?.id
        : null;
      data.conversation_id = activeChat?._id;
      const messageObj = await ChatService.generateMessageObj(data);
      const conversation = activeChat;
      conversation["last_message"] = messageObj;
      conversation["last_message_sent_at"] = new Date();
      conversation["unread"] = 0;
      params["reference_id"] = messageObj.reference_id;
      await LiveKitService.sendMessage({
        roomName: "alarabia",
        localParticipant: localParticipant,
        message: { messageObj, conversation },
        dId: [opponentId, useContext?.user?.id],
        topic: "CHAT",
        userName: userName,
      });
      setRepliedMessage(null);
      const response = await ChatService.sendMessage(id, params);
      const { success, messages, error, meta } = response;
      return { success, messages, error, meta };
    },
    [activeChat, repliedMessage]
  );

  const messageForwards = async (params: {
    message_id?: [string];
    user_id?: [string];
    group_id?: [string];
  }) => {
    setIsLoading(true);
    const response = await ChatService.forwards(params);
    const { success, messages, error, meta } = response;
    await LiveKitService.sendMessage({
      roomName: "alarabia",
      localParticipant: useContext?.user.id,
      message: { response },
      dId: params.user_id,
      topic: "FORWARD_MESSAGE",
      userName: useContext?.user.name,
    });
    listConversation({});
    setIsLoading(false);
    return { success, messages, error, meta };
  };

  const messageEdit = async (id: string, params: any) => {
    const response = await ChatService.editMessage(id, params);
    const dI = activeChat.participants.find(
      (item: any) => item._id !== useContext?.user?.id
    )._id;
    const messageObj = response?.data?.messageInfo;
    const conversation = activeChat;
    if (activeChat?.last_message?.reference_id === messageObj?.reference_id) {
      conversation["last_message"] = messageObj;
      conversation["last_message_sent_at"] = new Date();
      conversation["unread"] = 0;
    }
    await LiveKitService.sendMessage({
      roomName: "alarabia",
      localParticipant: useContext?.user?.id,
      message: { messageObj, conversation },
      dId: [dI, useContext?.user?.id],
      topic: "MESSAGE_UPDATE",
      userName: useContext?.user?.name,
    });
    const { success, messages, error, meta } = response;
    return { success, messages, error, meta };
  };

  const messageDelete = async (id: string) => {
    const response = await ChatService.deleteMessage(id);
    const { success, message, error, meta } = response;
    if (success) {
      setMessages((prevMessages: any) => {
        if (!Array.isArray(prevMessages)) {
          console.error("Messages is not an array:", prevMessages);
          return [];
        }
        const updatedMessages = [...prevMessages];
        const index = updatedMessages.findIndex(
          (item: any) => item.reference_id === message.reference_id
        );
        if (index > -1) {
          updatedMessages.splice(index, 1);
        }
        return updatedMessages;
      });
    }
    return { success, message, error, meta };
  };

  const createGroup = async (params: any) => {
    setIsLoading(true);
    const response = await ChatService.createGroup(params);
    if (response?.data?.success) {
      setActiveChat(response?.data?.conversation);
      await LiveKitService.sendMessage({
        roomName: "alarabia",
        localParticipant: useContext?.user?.id,
        message: response?.data?.group,
        dId: params,
        topic: "GROUP",
        userName: useContext?.user?.name,
      });
    }
    setIsLoading(false);
    return response?.data;
  };

  const addMemberToGroup = async (
    id: string,
    params: { group_member: string[] }
  ) => {
    const response = await ChatService.addMemberToGroup(id, params);
    if (response?.data?.success) {
      await LiveKitService.sendMessage({
        roomName: "alarabia",
        localParticipant: useContext?.user?.id,
        message: response?.data?.groupInfo,
        dId: response?.data?.groupInfo?.group_id?.group_member,
        topic: "GROUP_UPDATE",
        userName: useContext?.user?.name,
      });
    }
  };

  const updateGroupName = async (id: string, params: any) => {
    setIsLoading(true);
    const response = await ChatService.updateGroupName(id, params);
    if (response?.data?.success) {
      await LiveKitService.sendMessage({
        roomName: "alarabia",
        localParticipant: useContext?.user?.id,
        message: response?.data?.groupInfo,
        dId: response?.data?.groupInfo?.group_id?.group_member,
        topic: "GROUP_UPDATE",
        userName: useContext?.user?.name,
      });
    }
    setIsLoading(false);

    return response;
  };

  const forwardList = async (search?: string) => {
    const response = await ChatService.forwardList(search);
    setForwardListWithGroup(response?.data);
  };

  const removeUserFromGroup = async (grpId: string, memberId: string) => {
    setIsLoading(true);
    const response = await ChatService.removeUserFromGroup(grpId, memberId);
    if (response?.data?.success) {
      await LiveKitService.sendMessage({
        roomName: "alarabia",
        localParticipant: useContext?.user?.id,
        message: response?.data?.groupInfo,
        dId: response?.data?.groupInfo?.group_id?.group_member,
        topic: "GROUP_UPDATE",
        userName: useContext?.user?.name,
      });
    }
    setIsLoading(false);
    return response;
  };

  const fileUpload = async (data: any, feature: string) => {
    setIsLoading(true);
    const response = await FileService.fileUpload(data, feature);
    const { success, url, error } = response as FileInterface;
    setIsLoading(false);
    return { success, url, error };
  };

  return (
    <ChatContext.Provider
      value={{
        setIsLoading,
        isLoading,
        user,
        inbox,
        activeChat,
        onChangeChat: handleChangeChat,
        listConversation,
        getMessagesByConversationID,
        messages,
        meta,
        sendMessage,
        conversationList,
        fileUpload,
        getIndividualMessageID,
        repliedMessage,
        setRepliedMessage,
        messageForwards,
        forwardMessageCount,
        setForwardMessageCount,
        setMessages,
        newConversation,
        readConversation,
        setAttachment,
        attachment,
        setIsPreview,
        isPreview,
        mode,
        setMode,
        handleIncommingCall,
        userInfo,
        callRequested,
        setCallRequested,
        handleDisconnect,
        setAudioCallshow,
        audioCallshow,
        videoCallshow,
        setVideoCallshow,
        handleAcceptCall,
        isCallAccepted,
        setCallAccepted,
        messageEdit,
        messageDelete,
        createGroup,
        addMemberToGroup,
        updateGroupName,
        removeUserFromGroup,
        groupMembers,
        setGroupMembers,
        deleteMessage,
        setDeleteMessage,
        editGroupName,
        setEditGroupName,
        listCallHistory,
        callLogs,
        callerId,
        selectedMsgIds,
        setSelectedMsgIds,
        messageLimit,
        setMessageLimit,
        audio,
        ringTone,
        setExitGroup,
        exitGroup,
        isGroup,
        setIsGroup,
        callWaiting,
        isDisconnecting,
        callInitializing,
        forwardList,
        forwardListWithGroup,
        setForwardListWithGroup,
        newMessage,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}

export const useChatContext = () => React.useContext(ChatContext);
const Notify = ({ message }: { message: string }) => {
  count++;
  //WIP:need to handle notify. Two times hitting from chat provider handled temporary
  if (count % 2 === 0) {
    toast["success"](message, {
      autoClose: 3000,
      position: "top-right",
    });
  }
};
