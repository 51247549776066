import axios from "axios";
import { InviteMemberProps } from "../context/MembersProvider";

export const BASE_URL = process.env["REACT_APP_BASE_URL"];
export const HELP_CENTER_BASE_URL = process.env["REACT_HELP_CENTER_BASE_URL"];
export const SOCKET_URL = process.env["REACT_APP_SOCKET_URL"];

console.log(HELP_CENTER_BASE_URL, "HELP_CENTER_BASE_URL");

const filterQueryParams = (params: any) => {
  if (params) {
    Object.keys(params).forEach((key) => {
      if (
        params[key] === null ||
        params[key] === "null" ||
        params[key]?.length < 1 ||
        params[key] === ""
      ) {
        delete params[key];
      }
    });
    let rating: Array<number> = [];
    if (params?.rating?.length) {
      rating = [...params.rating];
      delete params.rating;
    }
    const searchParams = new URLSearchParams(params);
    rating.map((rate) => searchParams.append("rating", rate.toString()));
    return searchParams.toString();
  }
};

const post = async (
  url: string,
  token: string | null,
  data = {},
  _config = {}
) => {
  const config: any = { ..._config };
  if (token) {
    config.headers = { Authorization: `Bearer ${token}` };
  }
  return axios
    .post(url, data, { ...config })
    .then((res: any) => {
      if (res.status) {
        const { data, status } = res;
        return { data, status };
      } else {
        return {
          status: 200,
          data: res,
        };
      }
    })
    .catch(({ response }: any) => {
      return response;
    });
};

const put = async (
  url: string,
  token: string | null,
  data = {},
  _config = {}
) => {
  const config: any = { ..._config };
  if (token) {
    config.headers = { Authorization: `Bearer ${token}` };
  }
  return axios
    .put(url, data, { ...config })
    .then((res: any) => {
      if (res.status) {
        const { data, status } = res;
        return { data, status };
      } else {
        return {
          status: 200,
          data: res,
        };
      }
    })
    .catch(({ response }: any) => {
      return response;
    });
};

export const get = async (url: string, token: string | null, _config: any = {}) => {
  const config: any = { ..._config };
  if (token) {
    config.headers = { Authorization: `Bearer ${token}` };
  }
  return await axios
    .get(url, { ...config })
    .then((res: any) => {
      if (res.status) {
        return res.data;
      } else {
        return res.data;
      }
    })
    .catch(({ response }: any) => {
      return response?.data;
    });
};

const _delete = async (
  url: string,
  token: string | null,
  _config: any = {}
) => {
  const config: any = { ..._config };
  if (token) {
    config.headers = { Authorization: `Bearer ${token}` };
  }
  return await axios
    .delete(url, { ...config })
    .then((res: any) => {
      if (res.status) {
        return res.data;
      } else {
        return res.data;
      }
    })
    .catch(({ response }: any) => {
      return response.data;
    });
};

export const getCookie = (token: any) => {
  return sessionStorage.getItem(token);
};

export const api = {
  signIn: async (email: string, password: string) => {
    try {
      const res = await post(`${BASE_URL}/login`, null, {
        identifier: email?.replaceAll(" ", "")?.toLowerCase(),
        password,
      });
      if (res?.status === 400) {
        return {
          success: false,
          error: res.data.error,
        };
      } else if (res?.statusCode === 401) {
        return {
          success: false,
          error: ["Incorrect E-mail Address or password"],
        };
      }
      return res.data;
    } catch (e) {
      return {
        success: false,
        error: e,
      };
    }
  },

  getUserRoles: (role: string) =>
    get(`${BASE_URL}/roles/${role}`, getCookie("auth")),
  getUserRolesConfig: () => get(`${BASE_URL}/roles/config`, getCookie("auth")),
  getRolesDetails: () => get(`${BASE_URL}/roles`, getCookie("auth")),
  getNotification: () => get(`${BASE_URL}/notification`, getCookie("auth")),
  updateRoles: (params: any) =>
    put(`${BASE_URL}/roles`, getCookie("auth"), params),
  forgetPassword: (email: string) =>
    post(`${BASE_URL}/forgetPassword`, null, {
      identifier: email?.replaceAll(" ", "")?.toLowerCase(),
    }),
  resetPassword: (
    token: string,
    password: string,
    passwordConfirmation: string
  ) =>
    post(`${BASE_URL}/resetPassword`, null, {
      token,
      password,
      password_confirmation: passwordConfirmation,
    }),

  user: () => get(`${BASE_URL}/user`, getCookie("auth")),
  userVehicle: () => get(`${BASE_URL}/vehicle`, getCookie("auth")),
  updateUserVehicle: (params: any) =>
    put(`${BASE_URL}/vehicle`, getCookie("auth"), params),
  // updatePassword: () => get(`${BASE_URL}/changePassword`, getCookie("auth")),
  updatePassword: (value: any) =>
    post(`${BASE_URL}/changePassword`, getCookie("auth"), value),
  updateMemberPassword: (id: any, value: any) =>
    post(`${BASE_URL}/members/${id}/changePassword`, getCookie("auth"), value),
  blockMember: (id: any) =>
    post(`${BASE_URL}/members/${id}/block`, getCookie("auth"), {}),
  deleteMember: (id: any) =>
    _delete(`${BASE_URL}/members/${id}`, getCookie("auth"), {}),
  unBlockMember: (id: any) =>
    post(`${BASE_URL}/members/${id}/unblock`, getCookie("auth"), {}),
  updateUser: (params: any) =>
    put(`${BASE_URL}/user`, getCookie("auth"), params),

  members: (params: any) =>
    get(`${BASE_URL}/members?${filterQueryParams(params)}`, getCookie("auth")),
  getMembers: (params: any) =>
    get(`${BASE_URL}/members?${filterQueryParams(params)}`, getCookie("auth")),
  member: (id: string) => get(`${BASE_URL}/members/${id}`, getCookie("auth")),
  updateMember: (id: string, params: any) =>
    put(`${BASE_URL}/members/${id}`, getCookie("auth"), params),
  memberVehicle: (id: string) =>
    get(`${BASE_URL}/members/${id}/vehicles`, getCookie("auth")),
  updateMemberVehicle: (id: string, params: any) =>
    put(`${BASE_URL}/members/${id}/vehicles`, getCookie("auth"), params),

  autoCompleteApi: (params: any) =>
    get(
      filterQueryParams(params)
        ? `${BASE_URL}/members/autoComplete?${filterQueryParams(params)}`
        : `${BASE_URL}/members/autoComplete`,
      getCookie("auth")
    ),
  inviteMember: (params: InviteMemberProps) =>
    post(`${BASE_URL}/members/invite`, getCookie("auth"), {
      department: params.department,
      role: params.role,
      identifier: params.email?.replaceAll(" ", "")?.toLowerCase(),
    }),
  uploadTimeSheet: (params: any) =>
    put(`${BASE_URL}/members/timeSchedule`, getCookie("auth"), params),
  reSendInviteMember: (params: InviteMemberProps, id: any) =>
    post(`${BASE_URL}/members/${id}/resendInvitation`, getCookie("auth"), {
      department: params.department,
      role: params.role,
      identifier: params.email?.replaceAll(" ", "")?.toLowerCase(),
    }),

  billBoards: (params: any) =>
    get(
      `${BASE_URL}/billboards?${filterQueryParams(params)}`,
      getCookie("auth")
    ),

  billBoardLocation: (params: any) =>
    get(
      `${BASE_URL}/billboards/location?${filterQueryParams(params)}`,
      getCookie("auth")
    ),

  billBoardBoundaryBox: (params: any) =>
    get(
      `${BASE_URL}/billboards/boundaryBox?${filterQueryParams(params)}`,
      getCookie("auth")
    ),

  billBoard: (id: string) =>
    get(`${BASE_URL}/billboard/${id}`, getCookie("auth")),
  createBillBoard: (params: any) =>
    post(`${BASE_URL}/billboard`, getCookie("auth"), params),
  updateBillBoard: (id: string, params: any) =>
    put(`${BASE_URL}/billboard/${id}`, getCookie("auth"), params),
  uploadBillBoard: (params: any) =>
    post(`${BASE_URL}/billboard/uploadSheet`, getCookie("auth"), params),
  cityAutoComplete: (params: any) =>
    get(
      `${BASE_URL}/billboard/autoComplete?${filterQueryParams(params)}`,
      getCookie("auth")
    ),
  tickets: (params: any) =>
    get(`${BASE_URL}/tickets?${filterQueryParams(params)}`, getCookie("auth")),
  ticketsDetails: (params: any) =>
    get(`${BASE_URL}/ticket/stats/overView?${filterQueryParams(params)}`, getCookie("auth")),
  ticket: (id: string) => get(`${BASE_URL}/ticket/${id}`, getCookie("auth")),
  createTicket: (params: any) =>
    post(`${BASE_URL}/ticket`, getCookie("auth"), params),
  updateTicket: (id: string, params: any) =>
    put(`${BASE_URL}/ticket/${id}`, getCookie("auth"), params),
  getTicketBasedOnBillBoard: (id: any) =>
    get(`${BASE_URL}/tickets/billboard/${id}`, getCookie("auth")),
  getTicketActivityFeed: (id: any) =>
    get(`${BASE_URL}/ticket/${id}/ticketsActivities`, getCookie("auth")),
  deleteTicket: (id: any) =>
    _delete(`${BASE_URL}/ticket/${id}`, getCookie("auth"), {}),
  approveByStaffTicket: (id: any) =>
    post(`${BASE_URL}/ticket/${id}/approvedBy/manager`, getCookie("auth"), {}),

  
  approveBySupervisorTicket: (id: any, params: any) =>
    post(
      `${BASE_URL}/ticket/${id}/approvedBy/supervisor`,
      getCookie("auth"),
      params
    ),
  rejectTicket: (id: any) =>
    post(`${BASE_URL}/ticket/${id}/reject`, getCookie("auth"), {}),
  reassignTicket: (id: any, params: any) =>
    post(`${BASE_URL}/ticket/${id}/reassign`, getCookie("auth"), params),
  verifyToken: async (token: any) => get(`${BASE_URL}/${token}/verify`, null),
  verifyTokenpost: (token: any, values: any) =>
    post(`${BASE_URL}/invite/${token}/accept`, null, values),
  uploadFiles: (data: any, feature: any) =>
    post(
      `${BASE_URL}/file/upload?feature=${feature}`,
      getCookie("auth"),
      data,
      { headers: { "content-type": "multipart/form-data" } }
    ),
  nonOperationalBillBoard: (id: any, reason: any) =>
    post(`${BASE_URL}/billboard/${id}/nonOperational`, getCookie("auth"), {
      reason: reason,
    }),
  markOperationalBillBoard: (id: any) =>
    post(`${BASE_URL}/billboard/${id}/operational`, getCookie("auth"), {}),
  rejectNonOperationalBillBoard: (id: any, parmas: any) =>
    post(
      `${BASE_URL}/billboard/${id}/nonOperational/reject`,
      getCookie("auth"),
      parmas
    ),
  deleteBillboard: (id: any) =>
    _delete(`${BASE_URL}/billboard/${id}`, getCookie("auth"), {}),
  approvenNonOperationalBillBoard: (id: any) =>
    post(
      `${BASE_URL}/billboard/${id}/nonOperational/approve`,
      getCookie("auth"),
      {}
    ),
  generateQrCode: (boardNo: any) =>
    post(`${BASE_URL}/billboard/${boardNo}`, getCookie("auth"), {}),
  getTicketTitle: (value: any) =>
    get(
      value?.length > 0
        ? `${BASE_URL}/tickets/config/title?title=${value}`
        : `${BASE_URL}/tickets/config/title`,
      getCookie("auth"),
      {}
    ),
  urlToBase64: (params: any) =>
    post(`${BASE_URL}/file/base64`, getCookie("auth"), params),
  getAllConversation: (params: any) =>
    get(
      filterQueryParams(params)
        ? `${BASE_URL}/conversation?${filterQueryParams(params)}`
        : `${BASE_URL}/conversation`,
      getCookie("auth")
    ),
  createConversation: (params: any) =>
    post(`${BASE_URL}/group`, getCookie("auth"), params),
  inviteGroupConversation: (params: any, id: any) =>
    post(`${BASE_URL}/invite_group/${id}`, getCookie("auth"), params),
  updateConversation: (params: any, id: any) =>
    post(`${BASE_URL}/conversation/${id}`, getCookie("auth"), params),
  memberConversation: (id: any) =>
    post(`${BASE_URL}/conversation/member/${id}`, getCookie("auth"), {}),
  getConversation: (id: any, params: any) =>
    get(
      filterQueryParams(params)
        ? `${BASE_URL}/conversation/${id}?${filterQueryParams(params)}`
        : `${BASE_URL}/conversation/${id}`,
      getCookie("auth"),
      {}
    ),
  forwordMessage: (params: any) =>
    post(`${BASE_URL}/forward/messages`, getCookie("auth"), params),
  getGroupMembers: (id: any) =>
    get(`${BASE_URL}/group/${id}`, getCookie("auth")),
  addMemberGroup: (id: any, params: any) =>
    post(`${BASE_URL}/group/${id}/addMember`, getCookie("auth"), params),
  removeMemberGroup: (grp_id: any, member_id: any) => {
    put(`${BASE_URL}/removeUser/${grp_id}/${member_id}`, getCookie("auth"), {});
  },
  readMessage: (id: any) => {
    post(`${BASE_URL}/read/conversation/${id}`, getCookie("auth"), {});
  },

  getFowardList: (params: any) =>
    get(
      filterQueryParams(params)
        ? `${BASE_URL}/message/forwardlist?${filterQueryParams(params)}`
        : `${BASE_URL}/message/forwardlist`,
      getCookie("auth"),
      {}
    ),
  getMessageDetailById: (id: any) =>
    get(`${BASE_URL}/message/${id}`, getCookie("auth"), {}),
  getAllConfig: (params: any) =>
    get(
      filterQueryParams(params)
        ? `${BASE_URL}/config?${filterQueryParams(params)}`
        : `${BASE_URL}/config`,
      getCookie("auth"),
      {}
    ),
  getConfigById: (id: string) =>
    get(`${BASE_URL}/config/${id}`, getCookie("auth"), {}),
  createConfig: (params: any) =>
    post(`${BASE_URL}/config`, getCookie("auth"), params),
  updateConfig: (id: string, params: any) =>
    put(`${BASE_URL}/config/${id}`, getCookie("auth"), params),
  deleteConfig: (id: string) =>
    _delete(`${BASE_URL}/config/${id}`, getCookie("auth"), {}),
  getAllCleangingConfig: (params: any) =>
    get(
      filterQueryParams(params)
        ? `${BASE_URL}/cleaning/master/info?${filterQueryParams(params)}`
        : `${BASE_URL}/cleaning/master/info`,
      getCookie("auth"),
      {}
    ),
  getCleaningConfigById: (id: string) =>
    get(`${BASE_URL}/cleaning/master/${id}`, getCookie("auth"), {}),
  createCleaningConfig: (params: any) =>
    post(`${BASE_URL}/cleaning/master`, getCookie("auth"), params),
  updateCleaningConfig: (id: string, params: any) =>
    put(`${BASE_URL}/cleaning/master/${id}`, getCookie("auth"), params),
  deleteCleaningConfig: (id: string) =>
    _delete(`${BASE_URL}/cleaning/master/${id}`, getCookie("auth"), {}),
  getBilllofMaterial: (id: any, params: any) =>
    get(
      filterQueryParams(params)
        ? `${BASE_URL}/billboard/${id}/bom?${filterQueryParams(params)}`
        : `${BASE_URL}/billboard/${id}/bom`,
      getCookie("auth"),
      params
    ),

  // ---------------------------------------------------------------Dashboard api started----------------------------------------------------

  getStatsOverview: (dateValue: any, data: any) =>
    get(
      `${BASE_URL}/stats/overview?date=${dateValue}&type=${data}`,
      getCookie("auth")
    ),

  getStatusTicket: (dateValue: any, data: any) =>
    get(
      `${BASE_URL}/stats/ticket/status?date=${dateValue}&type=${data}`,
      getCookie("auth")
    ),

  getPriorityTicket: (dateValue: any, data: any) =>
    get(
      `${BASE_URL}/stats/ticket/priority?date=${dateValue}&type=${data}`,
      getCookie("auth")
    ),

  getDepartmenTicket: (dateValue: any, data: any) =>
    get(
      `${BASE_URL}/stats/ticket/department?date=${dateValue}&type=${data}`,
      getCookie("auth")
    ),

  getStatusPriorityTicket: (dateValue: any, data: any) =>
    get(
      `${BASE_URL}/stats/ticket/statusPriority?date=${dateValue}&type=${data}`,
      getCookie("auth")
    ),
  getLocationBasedTicket: (params: any, data: string) =>
    get(
      params
        ? `${BASE_URL}/stats/location/billboard?${filterQueryParams(params)}`
        : `${BASE_URL}/stats/location/billboard`,
      getCookie("auth")
    ),

  // ---------------------------------------------------inventory api startde------------------------------------------

  inventorySupplier: (params: any) =>
    get(
      params
        ? `${BASE_URL}/inventory/suppliers?${filterQueryParams(params)}`
        : `${BASE_URL}/inventory/suppliers`,
      getCookie("auth")
    ),

  addSupplier: (params: any) =>
    post(`${BASE_URL}/inventory/supplier`, getCookie("auth"), params),

  supplier: (id: string) =>
    get(`${BASE_URL}/inventory/supplier/${id} `, getCookie("auth")),

  updateSupplier: (id: string, params: any) =>
    put(`${BASE_URL}/inventory/supplier/${id}`, getCookie("auth"), params),
  deleteSupplier: (id: any) =>
    _delete(`${BASE_URL}/inventory/supplier/${id}`, getCookie("auth")),

  inventoryMaterial: (params: any) =>
    get(
      params
        ? `${BASE_URL}/inventory/stocks?${filterQueryParams(params)} `
        : `${BASE_URL}/inventory/stocks`,
      getCookie("auth")
    ),

  addMaterial: (params: any) =>
    post(`${BASE_URL}/inventory/stock`, getCookie("auth"), params),

  material: (id: string) =>
    get(`${BASE_URL}/inventory/stock/${id} `, getCookie("auth")),

  updateMaterial: (id: string, params: any) =>
    put(`${BASE_URL}/inventory/stock/${id}`, getCookie("auth"), params),
  stockAutoComplete: (params: any) =>
    get(
      params
        ? `${BASE_URL}/inventory/stock/supplier/info?${filterQueryParams(
          params
        )} `
        : `${BASE_URL}/inventory/stock/supplier/info`,
      getCookie("auth"),
      {}
    ),

  generateReports: (params: any) =>
    get(
      params
        ? `${BASE_URL}/inventory/stockReport/excelDownload?${filterQueryParams(
          params
        )}`
        : `${BASE_URL}/inventory/stockReport/excelDownload`,
      getCookie("auth"),
      { responseType: "blob" }
    ),
  generateTechnicianReport: (params: any) =>
    get(
      params
        ? `${BASE_URL}/inventory/technicianReport/excelDownload?${filterQueryParams(
          params
        )}`
        : `${BASE_URL}/inventory/technicianReport/excelDownload`,
      getCookie("auth"),
      { responseType: "blob" }
    ),
  // ---------------------------------------------------purchase-----------------------------------
  inventoryPurchase: (params: any) =>
    get(
      params
        ? `${BASE_URL}/inventory/purchases?${filterQueryParams(params)} `
        : `${BASE_URL}/inventory/purchases`,
      getCookie("auth")
    ),
  addPurchase: (params: any) =>
    post(`${BASE_URL}/inventory/purchase`, getCookie("auth"), params),

  purchase: (id: string) =>
    get(`${BASE_URL}/inventory/purchase/${id} `, getCookie("auth")),

  updatePurchase: (id: string, params: any) =>
    put(`${BASE_URL}/inventory/purchase/${id}`, getCookie("auth"), params),
  deletePurchase: (id: any) =>
    _delete(`${BASE_URL}/inventory/purchase/${id}`, getCookie("auth")),

  // ---------------------------------------------------purchase-----------------------------------

  inventoryMaterialToTechnician: (params: any) =>
    get(
      params
        ? `${BASE_URL}/inventory/materialToTechnicians?${filterQueryParams(
          params
        )} `
        : `${BASE_URL}/inventory/materialToTechnicians`,
      getCookie("auth")
    ),

  addNewMaterialToTechnician: (params: any) =>
    post(
      `${BASE_URL}/inventory/materialToTechnician`,
      getCookie("auth"),
      params
    ),

  materialToTechnician: (id: string) =>
    get(
      `${BASE_URL}/inventory/materialToTechnicians/${id} `,
      getCookie("auth")
    ),

  updateMaterialToTechnician: (id: string, params: any) =>
    put(
      `${BASE_URL}/inventory/materialToTechnician/${id}`,
      getCookie("auth"),
      params
    ),
  deleteMaterialToTechnician: (id: any) =>
    _delete(
      `${BASE_URL}/inventory/materialToTechnician/${id}`,
      getCookie("auth")
    ),

  getStockAdjustments: (params: any) =>
    get(
      params
        ? `${BASE_URL}/inventory/stock_adjustments?${filterQueryParams(
          params
        )} `
        : `${BASE_URL}/inventory/stock_adjustments`,
      getCookie("auth")
    ),
  addStockAdjustment: (params: any) =>
    post(`${BASE_URL}/inventory/stock_adjustment`, getCookie("auth"), params),
  // ---------------------------------------------------materialToTechnicians-----------------------------------

  // ---------------------------------------------------ReturnMaterialFromTechnician-----------------------------------

  addNewReturnMaterialFromTechnician: (params: any) =>
    post(
      `${BASE_URL}/inventory/returnMaterialFromTechnician`,
      getCookie("auth"),
      params
    ),

  inventoryReturnMaterialFromTechnician: (params: any) =>
    get(
      params
        ? `${BASE_URL}/inventory/returnMaterialFromTechnician?${filterQueryParams(
          params
        )} `
        : `${BASE_URL}/inventory/returnMaterialFromTechnician`,
      getCookie("auth")
    ),

  returnMaterialFromTechnician: (id: string) =>
    get(
      `${BASE_URL}/inventory/returnMaterialFromTechnician/${id} `,
      getCookie("auth")
    ),

  updateReturnMaterialFromTechnician: (id: string, params: any) =>
    put(
      `${BASE_URL}/inventory/returnMaterialFromTechnician/${id}`,
      getCookie("auth"),
      params
    ),
  deleteReturnMaterialFromTechnician: (id: any) =>
    _delete(
      `${BASE_URL}/inventory/returnMaterialFromTechnician/${id}`,
      getCookie("auth")
    ),

  // ---------------------------------------------------ReturnMaterialFromTechnician-----------------------------------

  // ---------------------------------------------------ReturnMaterialToSupplier-----------------------------------

  inventoryReturnMaterialToSupplier: (params: any) =>
    get(
      params
        ? `${BASE_URL}/inventory/returnMaterialToSupplier?${filterQueryParams(
          params
        )} `
        : `${BASE_URL}/inventory/returnMaterialToSupplier`,
      getCookie("auth")
    ),

  addReturnMaterialToSupplier: (params: any) =>
    post(
      `${BASE_URL}/inventory/returnMaterialToSupplier`,
      getCookie("auth"),
      params
    ),

  ReturnMaterialToSupplier: (id: string) =>
    get(
      `${BASE_URL}/inventory/returnMaterialToSupplier/${id} `,
      getCookie("auth")
    ),

  updateReturnMaterialToSupplier: (id: string, params: any) =>
    put(
      `${BASE_URL}/inventory/returnMaterialToSupplier/${id}`,
      getCookie("auth"),
      params
    ),
  deleteReturnMaterialToSupplier: (id: any) =>
    _delete(
      `${BASE_URL}/inventory/returnMaterialToSupplier/${id}`,
      getCookie("auth")
    ),

  // ---------------------------------------------------ReturnMaterialToSupplier-----------------------------------

  // ---------------------------------------------------ReplaceMaterialToSupplier-----------------------------------

  inventoryReplaceMaterialToSupplier: (params: any) =>
    get(
      params
        ? `${BASE_URL}/inventory/replaceMaterialFromSupplier?${filterQueryParams(
          params
        )} `
        : `${BASE_URL}/inventory/replaceMaterialFromSupplier`,
      getCookie("auth")
    ),

  addReplaceMaterialToSupplier: (params: any) =>
    post(
      `${BASE_URL}/inventory/replaceMaterialFromSupplier`,
      getCookie("auth"),
      params
    ),

  ReplaceMaterialToSupplier: (id: string) =>
    get(
      `${BASE_URL}/inventory/replaceMaterialFromSupplier/${id} `,
      getCookie("auth")
    ),

  updateReplaceMaterialToSupplier: (id: string, params: any) =>
    put(
      `${BASE_URL}/inventory/replaceMaterialFromSupplier/${id}`,
      getCookie("auth"),
      params
    ),
  deleteReplaceMaterialToSupplier: (id: any) =>
    _delete(
      `${BASE_URL}/inventory/replaceMaterialFromSupplier/${id}`,
      getCookie("auth")
    ),
  // ---------------------------------------------------ReplaceMaterialToSupplier-----------------------------------

  // --------------------------------------------dowload apis------------------------------------------------------------------------

  membersDownload: (params: any) =>
    get(
      `${BASE_URL}/members/excelDownload?${filterQueryParams(params)}`,
      getCookie("auth"),
      { responseType: "blob" }
    ),
  billboardDownload: (params: any) =>
    get(
      `${BASE_URL}/billboards/excelDownload?${filterQueryParams(params)}`,
      getCookie("auth"),
      { responseType: "blob" }
    ),
  bomExcelDownload: (id: any, params: any) =>
    get(
      `${BASE_URL}/billboards/${id}/bom/excelDownload?${filterQueryParams(
        params
      )}`,
      getCookie("auth"),
      { responseType: "blob" }
    ),
  bomPdfDownload: (id: any, params: any) =>
    get(
      `${BASE_URL}/billboards/${id}/bom/pdfDownload?${filterQueryParams(
        params
      )}`,
      getCookie("auth"),
      { responseType: "blob" }
    ),
  ticketDownload: (params: any) =>
    get(
      `${BASE_URL}/tickets/excelDownload?${filterQueryParams(params)}`,
      getCookie("auth"),
      { responseType: "blob" }
    ),
  supplierDownload: (params: any) =>
    get(
      `${BASE_URL}/inventory/supplier/excelDownload?${filterQueryParams(
        params
      )}`,
      getCookie("auth"),
      { responseType: "blob" }
    ),
  materialDownload: (params: any) =>
    get(
      `${BASE_URL}/inventory/stock/excelDownload?${filterQueryParams(params)}`,
      getCookie("auth"),
      { responseType: "blob" }
    ),
  purchaseDownload: (params: any) =>
    get(
      `${BASE_URL}/inventory/purchase/excelDownload?${filterQueryParams(
        params
      )}`,
      getCookie("auth"),
      { responseType: "blob" }
    ),
  materialToTechnicianDownload: (params: any) =>
    get(
      `${BASE_URL}/inventory/materialToTechnician/excelDownload?${filterQueryParams(
        params
      )}`,
      getCookie("auth"),
      { responseType: "blob" }
    ),
  returnMaterialFromTechnicianDownload: (params: any) =>
    get(
      `${BASE_URL}/inventory/returnMaterialFromTechnician/excelDownload?${filterQueryParams(
        params
      )}`,
      getCookie("auth"),
      { responseType: "blob" }
    ),
  returnMaterialToSupplierDownload: (params: any) =>
    get(
      `${BASE_URL}/inventory/returnMaterialToSupplier/excelDownload?${filterQueryParams(
        params
      )}`,
      getCookie("auth"),
      { responseType: "blob" }
    ),
  replaceMaterialFromSupplierDownload: (params: any) =>
    get(
      `${BASE_URL}/inventory/replaceMaterialFromSupplier/excelDownload?${filterQueryParams(
        params
      )}`,
      getCookie("auth"),
      { responseType: "blob" }
    ),

  membersPdfDownload: (params: any) =>
    get(
      `${BASE_URL}/members/pdfDownload?${filterQueryParams(params)}`,
      getCookie("auth"),
      { responseType: "blob" }
    ),
  billboardPdfDownload: (params: any) =>
    get(
      `${BASE_URL}/billboards/pdfDownload?${filterQueryParams(params)}`,
      getCookie("auth"),
      { responseType: "blob" }
    ),
  ticketPdfDownload: (params: any) =>
    get(
      `${BASE_URL}/tickets/pdfDownload?${filterQueryParams(params)}`,
      getCookie("auth"),
      { responseType: "blob" }
    ),
  supplierPdfDownload: (params: any) =>
    get(
      `${BASE_URL}/inventory/supplier/pdfDownload?${filterQueryParams(params)}`,
      getCookie("auth"),
      { responseType: "blob" }
    ),
  materialPdfDownload: (params: any) =>
    get(
      `${BASE_URL}/inventory/stock/pdfDownload?${filterQueryParams(params)}`,
      getCookie("auth"),
      { responseType: "blob" }
    ),
  purchasePdfDownload: (params: any) =>
    get(
      `${BASE_URL}/inventory/purchase/pdfDownload?${filterQueryParams(params)}`,
      getCookie("auth"),
      { responseType: "blob" }
    ),
  materialToTechnicianPdfDownload: (params: any) =>
    get(
      `${BASE_URL}/inventory/materialToTechnician/pdfDownload?${filterQueryParams(
        params
      )}`,
      getCookie("auth"),
      { responseType: "blob" }
    ),
  returnMaterialFromTechnicianPdfDownload: (params: any) =>
    get(
      `${BASE_URL}/inventory/returnMaterialFromTechnician/pdfDownload?${filterQueryParams(
        params
      )}`,
      getCookie("auth"),
      { responseType: "blob" }
    ),
  returnMaterialToSupplierPdfDownload: (params: any) =>
    get(
      `${BASE_URL}/inventory/returnMaterialToSupplier/pdfDownload?${filterQueryParams(
        params
      )}`,
      getCookie("auth"),
      { responseType: "blob" }
    ),
  replaceMaterialFromSupplierPdfDownload: (params: any) =>
    get(
      `${BASE_URL}/inventory/replaceMaterialFromSupplier/pdfDownload?${filterQueryParams(
        params
      )}`,
      getCookie("auth"),
      { responseType: "blob" }
    ),
  qrCodeDownload: (params: any) =>
    post(
      `${BASE_URL}/billboards/qrCodeDownload?${filterQueryParams(params)}`,
      getCookie("auth"),
      {
        responseType: "blob",
      }
    ),

  getAreaWiseBillboard: (dateValue: any, data: any) =>
    get(
      `${BASE_URL}/stats/ticket/areaWiseTicket?date=${dateValue}&type=${data}`,
      getCookie("auth")
    ),

  // ---------------------------------------------------Cleaning Module-----------------------------------

  cleaninglist: (params: any) =>
    get(`${BASE_URL}/cleaning?${filterQueryParams(params)}`, getCookie("auth")),
  cleaning: (id: string) =>
    get(`${BASE_URL}/cleaning/${id}`, getCookie("auth")),

  createCleaning: (params: any) =>
    post(`${BASE_URL}/cleaning`, getCookie("auth"), params),
  updateCleaning: (id: string, params: any) =>
    put(`${BASE_URL}/cleaning/${id}`, getCookie("auth"), params),

  getCleaningStatsOverview: () =>
    get(`${BASE_URL}/cleaning/stats`, getCookie("auth")),

  cleaningExcelDownload: (params: any) =>
    get(
      `${BASE_URL}/cleaning/excelDownload?${filterQueryParams(params)}`,
      getCookie("auth"),
      { responseType: "blob" }
    ),

  cleaningPdfDownload: (params: any) =>
    get(
      `${BASE_URL}/cleaning/pdfDownload?${filterQueryParams(params)}`,
      getCookie("auth"),
      { responseType: "blob" }
    ),

  cleaningTicketlist: (id: string, params: any) =>
    get(
      params
        ? `${BASE_URL}/cleaning/${id}/tickets?${filterQueryParams(params)}`
        : `${BASE_URL}/cleaning/${id}/tickets`,
      getCookie("auth")
    ),

  cleaningTicketAction: (params: any) =>
    post(`${BASE_URL}/tickets/action`, getCookie("auth"), params),
  masterBillBoardsDetails: (params: any) =>
    get(
      `${BASE_URL}/cleaning/master/info?${filterQueryParams(params)}`,
      getCookie("auth")
    ),
  getBomList: (params: any) =>
    get(
      params
        ? `${BASE_URL}/bom?${filterQueryParams(params)}`
        : `${BASE_URL}/bom`,
      getCookie("auth")
    ),
  createBom: (params: any) =>
    post(`${BASE_URL}/bom`, getCookie("auth"), params),
  updateBom: (id: any, params: any) =>
    put(`${BASE_URL}/bom/${id}`, getCookie("auth"), params),
  getBom: (id: any) => get(`${BASE_URL}/bom/${id}`, getCookie("auth"), {}),
  deleteBom: (id: any) =>
    _delete(`${BASE_URL}/bom/${id}`, getCookie("auth"), {}),

  helpCenter: (value: any) =>
    post(
      `${"https://20.74.152.254/api"}/members/helpMessage`,
      getCookie("auth"),
      value
    ),
};

export const convertExcel = (res: any, type: string) => {
  var blob = new Blob([res], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
  });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = `${type}_${new Date().getTime()}.xlsx`;
  link.click();
};

export const convertPdf = (res: any, type: string) => {
  var blob = new Blob([res], {
    type: "application/x-pdf; application/pdf",
  });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = `${type}_${new Date().getTime()}.pdf`;
  link.click();
};

export const updateText = (data: any) => {
  return data?.length > 0
    ? data
      ?.toLowerCase()
      ?.split(" ")
      ?.map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(" ")
    : "";
};

export const stringToHexColor = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const color = (hash & 0x00ffffff).toString(16).toUpperCase();
  return "#" + "00000".substring(0, 6 - color.length) + color;
};
